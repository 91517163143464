import { Component, computed, inject, input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonIconTextWidget, UrlService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { Participant } from '@interfaces';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../application.service';
import { environment } from 'environments';

@Component({
	selector: 'application-participant-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		CommonButtonRowWidget,
		CommonButtonColumnWidget,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './participant-flyout.part.html',
	styleUrl: './participant-flyout.part.scss'
})
export class ApplicationParticipantFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private urlService = inject(UrlService);

	public readonly participant = input.required<Participant>();
	public readonly remove = signal(false);
	public readonly copyLink = signal(false);



	public removeInfo = computed(() => {
		const participant = this.participant();
		if (participant.status == 'Pending') return `Are you sure you want to remove this participant's invitation?\n\n${participant.firstName} will no longer be able to join the Application.`;
		return `Are you sure you want to remove this participant?\n\n${participant.firstName} has already completed their input for this application, removing them will delete them from your application. They will no longer be marked as a participant.`
	});

	close() {
		this.activeOffcanvas.close();
	}

	async removeParticipant() {
		await this.applicationService.removeParticipant(this.participant());
		this.close();
	}

	async copyToClipboard() {
		this.copyLink.set(true);
		const participant = this.participant();
		const application = this.applicationService.application();
		if (participant.code && application) {
			const code = this.applicationService.util.text.formatCode(participant.code);
			const url = this.urlService.getBaseUrl(environment, application.application.languageId) + `/applications/join/${code.toLowerCase()}`;
			await this.applicationService.util.clipboard.writeText(url);
		}
	}


	async resendInvite() {
		await this.applicationService.resendInvite(this.participant());
	}
}