import { Component, computed, inject } from '@angular/core';
import { CommonMessageWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'example-text-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonMessageWidget,
		NgbAccordionModule,
	],
	templateUrl: './text.page.html'
})

export class ExampleTextPage {
	private frame = inject(MobileFrameService);
	items = ['First', 'Second', 'Third'];


	constructor() {
		this.frame.setUrlMetadata({
			url: '/examples/text',
			backUrl: '/examples',
			pageName: computed(() => `Text`),
			headerText: computed(() => `Text`),
		});
	}
}