import { Injectable, computed, inject, signal } from "@angular/core";
import { CommonOptionsFieldConfig, CommonSelectField, CommonTextField, CommonTextFieldConfig, Option, UrlService } from "@eforall/common";
import { DomainDataService, FuncService } from '../../../../services';
import { ApplicationApplyService } from "../apply.service";
import { BusinessForApplication } from "../interfaces";

@Injectable({ providedIn: 'root' })
export class ApplicationApplyBusinessFormService {
	public applyService = inject(ApplicationApplyService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	private readonly domainData = inject(DomainDataService);

	public selectedBusiness = signal<BusinessForApplication | {
		companyId: number,
		companyTypeId?: number,
		industryId?: number,
		companyName: string,
		title: string,
		priorApplicant?: string,
	}>({
		companyId: 0,
		companyTypeId: undefined,
		industryId: undefined,
		companyName: '',
		title: '',
	});



	public readonly businessesOptions = computed(() => {
		const businesses = this.applyService.businesses().reduce((a: Option<number>[], business) => {
			a.push({ text: business.companyName, value: business.companyId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);


		businesses.push({
			text: 'Add a new business',
			value: -1,
		});

		return businesses;
	});



	readonly businessName: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Business Name', min: 5, max: 40, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.selectedBusiness().companyName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const selectedBusiness = this.selectedBusiness();
			this.selectedBusiness.set({ ...selectedBusiness, companyName: value });
		},
		error: signal(''),
	};



	private readonly businessTypeOptions = computed(() => {
		const domainData = this.domainData.data();
		return domainData.companyTypes.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.companyTypeId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly businessType: CommonSelectField<number> = {
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Business Type', options: this.businessTypeOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.selectedBusiness().companyTypeId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const selectedBusiness = this.selectedBusiness();
			this.selectedBusiness.set({ ...selectedBusiness, companyTypeId: value });
		},
		error: signal(''),
	};


	private readonly industryOptions = computed(() => {
		return this.domainData.data().industries.reduce((a: Option<number>[], type) => {
			a.push({ text: type.label, value: type.industryId, });
			return a;
		}, []).sort((a, b) => a.text < b.text ? -1 : 1);
	});

	public readonly industry: CommonSelectField<number> = {
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Industry', options: this.industryOptions(), required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.selectedBusiness().industryId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const selectedBusiness = this.selectedBusiness();
			this.selectedBusiness.set({ ...selectedBusiness, industryId: value });
		},
		error: signal(''),
	};


	readonly title: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Your Title', min: 3, max: 50, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.selectedBusiness().title || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const selectedBusiness = this.selectedBusiness();
			this.selectedBusiness.set({ ...selectedBusiness, title: value });
		},
		error: signal(''),
	};



	public canApply = computed<boolean>(() => {
		return !!this.selectedBusiness().companyId
			&& !!this.businessName.actualValue()
			&& !!this.businessType.actualValue()
			&& !!this.title.actualValue();
	});



	onChange(value: number) {
		const business = this.applyService.businesses().find(business => business.companyId == value);
		this.selectedBusiness.set(business || {
			companyId: -1,
			companyTypeId: undefined,
			industryId: undefined,
			companyName: '',
			title: '',
		});
	}



	async apply() {
		const selectedBusiness = this.selectedBusiness();
		if (!this.canApply() || selectedBusiness.priorApplicant) return;

		const values = {
			companyName: this.businessName.actualValue(),
			industryId: this.industry.actualValue(),
			companyTypeId: this.businessType.actualValue(),
			title: this.title.actualValue(),
		};

		if (selectedBusiness.companyId == -1) {
			//CREATE AND APPLY
			await this.applyService.createCompanyAndAddApplication(values);

		}
		else {
			// UPDATE AND APPLY

			if (
				selectedBusiness.companyTypeId != values.companyTypeId
				|| selectedBusiness.companyName != values.companyName
				|| selectedBusiness.industryId != values.industryId
				|| selectedBusiness.title != values.title
			) {
				await this.applyService.updateCompanyAndPosition(selectedBusiness.companyId, selectedBusiness.companyName, values.companyTypeId, values.industryId, values.title);
			}

			await this.applyService.addApplication(selectedBusiness.companyId, values.title);
		}

	}

}