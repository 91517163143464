<mobile-page-part>

	<div class="info-content">
		<h3>Join an Application</h3>
	</div>

	<div>
		<h4>Enter Code:</h4>
		<div class="input-group">
			<span class="input-group-text">
				@if(errorToShow() !== ''){<i class="fa-fw fa-solid fa-circle-xmark color-darkred"></i>}
				@else{<i class="fa-fw fa-regular fa-key"></i>}
			</span>
			<input type="text" class="form-control" id="application-code" placeholder="xxx-xxx-xxx"
				maxLength="11" [ngModel]="pendingCode()" (ngModelChange)="onChange($event)" style="text-transform: uppercase;">
		</div>
		@if(errorToShow() !== '')
		{
		<div class="color-darkred text-end">{{errorToShow()}}</div>
		}
	</div>

	<common-button-row [center]="true">
		<button class="btn btn-primary btn-large" (click)="validate()">Continue</button>
	</common-button-row>

</mobile-page-part>