import { Injectable, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { BREAKPOINT_COMPUTER, BREAKPOINT_TABLET, BREAKPOINT_WIDE_COMPUTER } from './mobile-layout-constants';
import { MobileLayoutDimensions } from './mobile-layout-dimensions';


@Injectable({ providedIn: 'root' })
export class MobileLayoutService {

	private readonly _size = toSignal(fromEvent(window, 'resize').pipe(startWith(undefined)));

	public readonly dimensions = computed<MobileLayoutDimensions>(() => {
		this._size();	// Read to recompute. Don't care about the result.
		return this.calcDimensions();
	});


	/**
	 * Calculate all of the layout sizes.
	 * This is called each time the window is resized.
	 */
	private calcDimensions(): MobileLayoutDimensions {

		//
		// The inner values are the browser window size 
		//
		const w = window.innerWidth;
		const h = window.innerHeight;

		//
		// Determine what breakpoint range the window width equates to
		//
		let breakpoint: 'phone' | 'tablet' | 'computer' | 'wide-computer' = 'phone';

		if (w >= BREAKPOINT_WIDE_COMPUTER) breakpoint = 'wide-computer';
		else if (w >= BREAKPOINT_COMPUTER) breakpoint = 'computer';
		else if (w > BREAKPOINT_TABLET) breakpoint = 'tablet';
		else breakpoint = 'phone';

		const d: MobileLayoutDimensions = {
			breakpoint,
			screenWidth: window.outerWidth,
			screenHeight: window.outerHeight,
			windowWidth: w,
			windowHeight: h,
			contentHeight: h,
			contentWidth: w,
		};


		if (breakpoint != 'phone') {
			d.contentHeight = h - 36; //Padding added
		}

		return d;
	}
}