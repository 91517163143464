<mobile-page-part>

	<div class="info-content">
		@if(myTeamService.team(); as team){
		<h3>
			{{myTeamService.util.text
			.singularOrPluralWithCount(team.entrepreneurs.length + team.mentors.length,
			'Team Member',
			'Team Members')}}
		</h3>
		}
		@else {
		<small>No team</small>
		}
	</div>


	@if(myTeamService.team(); as team){
	<div class="white-box">
		<h4>{{team.data.name}}</h4>
		<div>Org Type: {{team.data.orgType}}</div>
		<div>Industry: {{team.data.industry}}</div>
		<div>Offerings: {{team.data.offerings}}</div>
		<div>Status: {{team.data.status}}</div>

		<common-button-row [center]='true'>
			@if(team.data.canEdit){
			<button class="btn btn-standard" routerLink="business-details">Edit</button>
			}
			<button class="btn btn-standard"
				[routerLink]="myTeamService.urlService.withCurrentLanguage('/applications/'+team.data.applicationId)">View
				Application</button>
		</common-button-row>
	</div>



	<mobile-list [items]="team.entrepreneurs" header="Entrepreneurs" noItemsMessage="No Entrepreneurs"></mobile-list>


	<mobile-list [items]="team.mentors" header="Mentors" noItemsMessage="No Mentors"></mobile-list>


	}


</mobile-page-part>