@if(form.applyService.selectedApplicationProgramInstance(); as program )
{
<div class="d-flex flex-column gap-2">

	<h3>{{program.siteName}} {{program.programInstance}} {{program.programName}} ({{program.language}})</h3>

	@if(descriptionAndLink(); as descriptionAndLink){
	<p class="white-box">
		{{descriptionAndLink.description}}
		@if(descriptionAndLink.link){
		<a class='link' [href]='descriptionAndLink.link'
			[target]='descriptionAndLink.link'>{{urlService.commonLabels.button.LearnMore()}}</a>
		}
	</p>
	}


	<div>
		<h4>
			Which business?
		</h4>
		<select class="form-select form-select-sm" [ngModel]="form.selectedBusiness().companyId"
			(ngModelChange)="form.onChange($event)">
			<option disabled value="0">Select...</option>
			@for(option of form.businessesOptions(); track option.value;)
			{
			<option [value]="option.value">{{option.text}}</option>
			}
		</select>
	</div>

	@if(form.selectedBusiness().priorApplicant; as priorApplicant){
	<div>{{alreadyAppliedForBy()}}: {{priorApplicant}}</div>
	}
	@else
	{
	@if(form.selectedBusiness().companyId){

	<common-text-field [field]="form.businessName"></common-text-field>
	<common-select-field [field]="form.businessType"></common-select-field>
	<common-select-field [field]="form.industry"></common-select-field>
	<common-text-field [field]="form.title"></common-text-field>


	<common-button-column>
		<button class="btn btn-primary btn-large" [disabled]="!form.canApply()" (click)="form.apply()">Create
			Application</button>
	</common-button-column>
	}
	}
</div>
}