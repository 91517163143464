import { Injectable, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonGeneralFieldConfig, CommonPhoneField, CommonTextField, UrlService, UtilityService } from "@eforall/common";
import { AppService, FuncService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class ProfileBasicInfoFormService {

	private readonly app = inject(AppService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);
	private readonly util = inject(UtilityService);

	readonly firstName: CommonTextField = {
		config: signal({ label: 'First Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.firstName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setFirstName({ firstName: value });
			this.app.setUserData({ ...user, firstName: value });
		},
		error: signal(''),
	};


	readonly lastName: CommonTextField = {
		config: signal({ label: 'Last Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.lastName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setLastName({ lastName: value });
			this.app.setUserData({ ...user, lastName: value });
		},
		error: signal(''),
	};

	readonly middleInit: CommonTextField = {
		config: signal({ label: 'M.I.', min: 0, max: 1, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.middleInit || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setMiddleInit({ middleInit: value });
			this.app.setUserData({ ...user, middleInit: value });
		},
		error: signal(''),
	};


	readonly phone: CommonPhoneField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Phone', required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setPhone({ phone: value });
			this.app.setUserData({ ...user, phone: value });
		},
		error: signal(''),
	};



	readonly address: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Address', isSpanish: false, required: true }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			if (value) {
				const user = this.app.data().user;
				await this.func.profile.form.setAddress({ address: value });
				this.app.setUserData({
					...user,
					inUS: value.inUS,
					zipId: value.zipId,
					address: value.streetAddress,
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});
			}
		},
		error: signal(''),
	};

}