import { Component, computed, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { AgreementService } from './agreements.service';

@Component({
	selector: 'agreements-page',
	standalone: true,
	imports: [
		MobilePagePart,
		MobileListWidget,
	],
	templateUrl: './agreements.page.html'
})

export class AgreementsPage implements OnInit {
	private frame = inject(MobileFrameService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	private util = inject(UtilityService);
	private agreementService = inject(AgreementService);

	public list = computed<MobileListWithSubItem[]>(() => {
		const agreements = this.agreementService.agreements();
		const isSpanish = this.urlService.isSpanish();

		return agreements.reduce((a: MobileListWithSubItem[], agreement) => {

			let icon = 'fa-circle';
			let subText = '';

			if (agreement.signedUTC) {
				icon += ' color-green';
				subText = `Signed on ${this.util.date.formatUTC(agreement.signedUTC, 'MMM D, YYYY', 'No Time', isSpanish ? 'es-US' : 'en-US')}`;
			}
			else if (agreement.signedOlderVersion) {
				icon += ' color-darkred';
				subText = `Signature Required - Updated on ${this.util.date.formatUTC(agreement.versionUpdatedUTC, 'MM/DD/YYYY', 'No Time', isSpanish ? 'es-US' : 'en-US')}`;
			}
			else {
				icon += ' color-lightgray';
				subText = `Signature Required`;
			}

			a.push({
				icon,
				iconStyle: 'fa-solid',
				text: isSpanish ? agreement.nameES : agreement.nameEN,
				subText,
				callback: () => this.navigateTo(`/agreements/${agreement.slug}`),
			});
			return a;
		}, []).sort((a, b) => a.text > b.text ? 1 : -1);

	});


	public agreementsInfoBar = computed(() => {
		const agreements = this.agreementService.agreements();

		const unsignedAgreements = agreements.filter(agreement => !agreement.signedUTC);
		if (unsignedAgreements.length) return `${unsignedAgreements.length} Unsigned Agreement${unsignedAgreements.length == 1 ? '' : 's'}`;

		return `${agreements.length} Signed Agreement${agreements.length == 1 ? '' : 's'}`;

	});


	constructor() {
		this.frame.setUrlMetadata({
			url: '/agreements',
			backUrl: '/',
			pageName: computed(() => `Agreements`),
			headerText: computed(() => `Agreements`),
		});
	}

	async ngOnInit() {
		await this.agreementService.getAndSetAgreements();
	}

	navigateTo(path: string) {
		this.router.navigate([this.urlService.withCurrentLanguage(path)]);
	}

}