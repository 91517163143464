<mobile-page-part [hideInfoBar]="true">

	<div class="d-flex flex-column gap-2">

		<common-text-field [field]="form.firstName"></common-text-field>
		<common-text-field [field]="form.middleInit"></common-text-field>
		<common-text-field [field]="form.lastName"></common-text-field>
		<common-phone-field [field]="form.phone"></common-phone-field>
		<common-address-field [field]="form.address"></common-address-field>

	</div>

</mobile-page-part>