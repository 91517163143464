<mobile-page-part [hideInfoBar]="true">

	<!-- <div class="d-flex flex-column gap-3"> -->

	<div class="white-box">
		You can sign in with any of the following emails.<br>
		Email communications will always be sent to your primary email.
	</div>

	<div>
		<h4>Primary Email</h4>
		<div class="white-box text-center">{{emails().primary}}</div>
	</div>


	@if(emails().others.length)
	{
	<div>
		<h4>Other Emails</h4>
		<div class="white-box d-flex flex-column gap-2">
			@for(email of emails().others; track email.emailId;){
			<div class="d-flex align-items-center gap-2">
				<div>{{email.email}}</div>
				<button class="btn btn-primary align-self-end ms-auto" (click)="setPrimaryEmail(email.emailId)">Make
					Primary</button>
				<button class="btn btn-standard align-self-end" (click)="removeEmail(email.emailId)">
					<i class='fa-regular fa-xmark'></i>
				</button>
			</div>
			}
		</div>
	</div>
	}
	<div>

		<h4 class="align-self-start">Add an Email</h4>
		<div class="white-box">
			Sign out and back in with a new email and follow the instructions, or ask a staff member to add an email
			directly to your account.
		</div>
	</div>


	<!-- </div> -->
</mobile-page-part>