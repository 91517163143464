import { Component, computed, inject } from '@angular/core';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'profile-bios-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './bios.page.html'
})

export class ProfileBiosPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/bios',
			backUrl: '/profile',
			pageName: computed(() => `Bios`),
			headerText: computed(() => `Bios`),
		});
	}

}