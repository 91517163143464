import { Component, computed, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, CommonOptionsFieldConfig, CommonRadioField, CommonRadioFieldWidget, UrlService, UtilityService } from '@eforall/common';
import { CourseActivityQuiz } from '@interfaces';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { FlyoutService } from '../../../../services';


@Component({
	selector: 'learning-quiz-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonButtonRowWidget,
		RouterLink,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
		CommonRadioFieldWidget,
	],
	providers: [provideMarkdown()],
	templateUrl: './quiz.part.html'
})

export class LearningQuizPart {

	public urlService = inject(UrlService);
	public util = inject(UtilityService);
	private flyoutService = inject(FlyoutService);

	public readonly quiz = input.required<CourseActivityQuiz>();
	public readonly quizSubmitted = output<{
		quizQuestionsId: number,
		selectedChoiceId: number,
	}[]>();
	public readonly tryAgainClicked = output<boolean>();



	private savedSelectedChoices: {
		[quizQuestionsId: number]: {
			quizQuestionsId: number,
			selectedChoiceId: number | undefined,
		}
	} = [];



	readonly questions = computed<{
		label: string,
		correctChoiceId: number,
		correctChoiceReason: string,
		field: CommonRadioField<number>
	}[]>(() => this.buildQuestionFields());




	private buildQuestionFields() {

		const quiz = this.quiz();

		const quizQuestions: {
			label: string,
			correctChoiceId: number,
			correctChoiceReason: string,
			field: CommonRadioField<number>
		}[] = [];

		for (const question of quiz.questions) {

			this.savedSelectedChoices[question.quizQuestionsId] = { quizQuestionsId: question.quizQuestionsId, selectedChoiceId: undefined };
			quizQuestions.push({
				correctChoiceId: question.correctQuizQuestionChoiceId,
				correctChoiceReason: `#### Correct Choice: ${question.correctQuizQuestionChoiceText}\n\n Reason: ${question.correctReasonText}`,
				label: question.questionText,
				field: {
					config: signal<CommonOptionsFieldConfig<number>>({
						label: this.urlService.commonLabels.form.Answer()!,
						options: question.choices.map(choice => ({ text: choice.choiceText, value: choice.quizQuestionChoiceId })),
						required: true,
						disabled: !!quiz.attemptSubmittedUTC,
					}),
					actualValue: computed(() => question.selectedChoiceId || 0),
					pendingValue: signal<number | null>(null),
					saving: signal(false),
					save: async (value: number) => {
						this.savedSelectedChoices[question.quizQuestionsId] = {
							quizQuestionsId: question.quizQuestionsId,
							selectedChoiceId: value,
						};
						console.log(this.savedSelectedChoices);
					},
					error: signal(''),
				}
			});

		}

		return quizQuestions;

	}



	public checkIfCanSubmit() {
		for (const quizQuestionsId in this.savedSelectedChoices) {
			if (!this.savedSelectedChoices[+quizQuestionsId]?.selectedChoiceId) return false;
		}
		return true;
	}

	public openFlyoutMessage(message: string) {
		this.flyoutService.showMarkdownMessage(message, 'Reason')
	}


	public submit() {
		const selectedChoices: { quizQuestionsId: number, selectedChoiceId: number, }[] = [];
		for (const quizQuestionsId in this.savedSelectedChoices) {
			const choice = this.savedSelectedChoices[+quizQuestionsId]!;
			selectedChoices.push({
				quizQuestionsId: choice.quizQuestionsId,
				selectedChoiceId: choice.selectedChoiceId!,
			});
		}
		this.quizSubmitted.emit(selectedChoices);
	}



	public addAttempt() {
		this.tryAgainClicked.emit(true);
	}
}