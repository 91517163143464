import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonColumnWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';

@Component({
	selector: 'home-page',
	standalone: true,
	imports: [
		MobilePagePart,
		RouterLink,
		CommonButtonColumnWidget,
	],
	templateUrl: './home.page.html'
})

export class HomePage {
	
	private frame = inject(MobileFrameService);
	public util = inject(UtilityService);

	public currentUTC = Date.now() / 1000;
	constructor() {
		this.frame.setUrlMetadata({
			url: '/',
			backUrl: '/',
			pageName: computed(() => `Home`),
			headerText: computed(() => `Home`),
		});
	}
}