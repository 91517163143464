<mobile-page-part>

	<div class="info-content">
		<h3>{{labels.AccSessionSurvey()}}</h3>
	</div>

	@if(!survey() || !survey()?.access){
	<common-message alertType="warning">You do not have access to this survey.</common-message>
	}
	@else {
	@if(survey(); as survey){
	@if(!sameLanguage()){

	<div>
		{{labels.GoToCorrectLanguageMsg()}}
		<common-button-row [center]="true">
			<button class="btn btn-primary">{{labels.OpenInLanguage()}}</button>
		</common-button-row>
	</div>


	}
	@if(showSurveyFields(); as data){
	<div>
		<h4 class="text-center">{{survey.access.accName}}</h4>
		<common-message>{{data.message}}</common-message>
	</div>

	<survey-fields-display [surveyFields]="data.surveyFields"></survey-fields-display>

	<common-button-row [center]="true">
		<button class='btn btn-primary btn-large' [disabled]="!checkIfAllFieldsAreFilled(data.surveyFields)"
			(click)='save()'>
			Submit
		</button>
	</common-button-row>

	}
	@else {
	@if(message(); as message)
	{
	<common-message [alertType]="message.alertType">{{message.label}}</common-message>
	}
	}
	}
	}


</mobile-page-part>