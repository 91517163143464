@if(item(); as item){
@if(item.callback)
{
<button (click)="item.callback()" class="btn-flat btn d-flex justify-content-left align-items-center gap-3" [style.height.px]='calculatedHeight()'>
	<i class='fa-fw fs-5 {{item.iconStyle}} {{item.icon}}'></i>
	<div class="d-flex flex-column text-start">
		<span>{{item.text}}</span>
		@if(item.subText)
		{
		<small>{{item.subText}}</small>
		}
	</div>
	<i class='fa-fw fa-regular fa-chevron-right ms-auto'></i>
</button>
}
@else
{
<button class="btn-flat btn d-flex justify-content-left align-items-center gap-3" [style.height.px]='calculatedHeight()'>
	<i class='fa-fw fs-5 {{item.iconStyle}} {{item.icon}}'></i>
	<div class="d-flex flex-column text-start">
		<span>{{item.text}}</span>
		@if(item.subText)
		{
		<small>{{item.subText}}</small>
		}
	</div>
</button>
}
}