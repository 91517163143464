import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { AngularEnvironment } from "../env";


/**
 * Sign in for internal apps (staff only)
 */
export async function internalSignIn(environment: AngularEnvironment) {

	if (environment.authType !== 'Internal') return;


	//
	// If the app is running from a DEV or PROD deployment, then we
	// authenticate using OIDC. Otherwise, use simple Google Auth.
	//
	const provider = environment.name == 'LOCALHOST'
		? new GoogleAuthProvider
		: new OAuthProvider('oidc.eforall-workspace');


	provider.setCustomParameters({
		prompt: 'select_account'
	});


	const auth = getAuth();

	//
	// Localhost must sign in with a popup because browsers no
	// longer support cross-domain cookies, and "localhost" is
	// not a registered domain within our apps.
	//
	if (environment.name == 'LOCALHOST') {
		await signInWithPopup(auth, provider);
	}
	else {
		await signInWithRedirect(auth, provider);
	}

}