<mobile-page-part>

	<div class="info-content">
		<h3>{{form.companyName()}}</h3>
	</div>


	<common-text-field [field]="form.businessName"></common-text-field>
	<common-text-field [field]="form.shortName"></common-text-field>
	<common-select-field [field]="form.businessType"></common-select-field>
	<common-select-field [field]="form.industry"></common-select-field>
	<common-select-field [field]="form.offering"></common-select-field>
	<common-select-field [field]="form.status"></common-select-field>

</mobile-page-part>