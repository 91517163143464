import { Component, input } from '@angular/core';

@Component({
	selector: 'mobile-logo-part',
	standalone: true,
	templateUrl: './mobile-logo.part.html',
	styleUrls: ['./mobile-logo.part.scss'],
})
export class MobileLogoPart {

	public readonly logo = input<'blue-full' | 'white-small' | 'white-full'>('blue-full');

}