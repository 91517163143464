<mobile-page-part>

	<div class="info-content">
		@if(applicationService.application(); as application){
		<h3>{{application.application.picId ? 'Pitch Contest Application': 'Accelerator Application'}}</h3>
		}
		@else {<small>Application does not exists for the user. TBD text</small>}
	</div>

	@if(applicationService.application(); as application)
	{

	@if(application.canUpdate)
	{
	@if(application.isCoParticipant)
	{

	<div class="white-box">
		<h3 class="text-center">{{application.name}}</h3>
		<div>Applicant: {{application.applicant.firstName}} {{application.applicant.lastName}}</div>
		<div>Business: {{application.business._name}}</div>
		<div>Deadline: {{application.deadline}}</div>
	</div>
	<h4 class="color-darkred text-center">
		Application not yet submitted
	</h4>
	<common-button-column>
		<button class="btn btn-primary btn-large"
			(click)="applicationService.flyoutService.showApplicationReadonly()">View Pending Application</button>
	</common-button-column>


	<mobile-list [items]="tellUsAboutYou()"></mobile-list>


	}
	<!-- If Applicant -->
	@else
	{
	<div class="white-box">
		<h3 class="text-center">{{application.name}}</h3>
		<div>Deadline: {{application.deadline}}</div>
		<div>Language: {{application.application.languageId === 2 ? 'Espanol':'English'}}</div>
		<common-button-column>
			<button class="btn btn-large"
				[ngClass]="{  'btn-primary': primaryBtn() === 'instructions',  'btn-standard': primaryBtn() !== 'instructions',}"
				(click)="openInstructions()">Instructions</button>
		</common-button-column>
	</div>

	<h4 class="color-darkred text-center">
		{{application.application.applicationStatusId > 3 ? 'Submitted': 'Not Submitted'}}:
		{{applicationService.stepsCompleted()}} of 4 steps complete
	</h4>

	@if(applicationService.stepsCompleted() === 4 && application.canSubmit) {
	<common-button-column>
		<button class="btn btn-primary btn-large"
			(click)="applicationService.flyoutService.showApplicationSubmission()">Submit</button>
	</common-button-column>
	}

	<mobile-list [items]="listItems()"></mobile-list>

	<common-button-column>
		@if(application.canSubmit){
		<button class="btn btn-large"
			[ngClass]="{  'btn-primary': primaryBtn() === 'submit',  'btn-standard': primaryBtn() !== 'submit',}"
			(click)="applicationService.flyoutService.showApplicationSubmission()">Submit</button>

		<button class="btn btn-standard" (click)="applicationService.openMakeChangesFlyout()">Withdraw</button>

		}
		@else if( application.canWithdraw || application.canReopen) {

		<button class="btn btn-standard btn-large" (click)="applicationService.openMakeChangesFlyout()">Make
			Changes</button>
		}
	</common-button-column>

	}
	}
	@else
	{
	<application-readonly-part></application-readonly-part>
	}
	}
	@else {
	<common-message alertType="warning">Not authorized</common-message>
	}

</mobile-page-part>