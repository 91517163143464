import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		Answer: computed(() => en() ? 'Answer (select one)' : 'Respuesta (seleccione una)'),
		Learning: computed(() => en() ? `Learning` : `{{ ES: Learning }}`),
		Question: computed(() => en() ? 'Question' : 'Pregunta'),
		TopicNoMaterialsMsg: computed(() => en() ? 'This topic does not have any course material.' : 'Este tema no tiene ningún material de curso.'),
	};
}