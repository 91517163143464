import { Injectable, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonOptionsFieldConfig, CommonSelectField, UrlService } from "@eforall/common";
import { AppService, FuncService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class ApplicationApplyFormService {

	private readonly urlService = inject(UrlService);
	private readonly func = inject(FuncService);
	private readonly app = inject(AppService);


	readonly homeAddress: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Home Address', isSpanish: this.urlService.isSpanish(), required: true }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			const user = this.app.data().user;
			if (value) {

				user.inUS = value.inUS;
				user.zipId = value.zipId;
				user.address = value.streetAddress;
				user.placeAddress = value.placeAddress;
				user.placeId = value.placeId;
				user.placeLatitude = value.latitude;
				user.placeLongitude = value.longitude;

				await this.func.profile.form.setAddress({ address: value });

				this.app.setUserData({ ...user });

			}
			this.homeAddress.saving.set(false);
		},
		error: signal(''),
	};


	private values = signal<{ location: string, address: AddressData | undefined }>({ location: '', address: undefined });

	private readonly businessLocationOptions = [
		{ value: 'N', text: !this.urlService.isSpanish() ? "I don't know / Undecided" : 'No sé / Indeciso' },
		{ value: 'Y', text: !this.urlService.isSpanish() ? "I know my Business location..." : "Sé cuál es la ubicaciónde mi negocio..." },
	];

	public readonly businessLocation: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Business Location', options: this.businessLocationOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().location),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, location: value });
		},
		error: signal(''),
	};




	readonly businessAddress: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Business ZIP Code', isSpanish: false, required: true }),
		actualValue: computed(() => this.values().address),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {
			const values = this.values();
			this.values.set({ ...values, address: value });
		},
		error: signal(''),
	};



	public resetFields() {
		this.values.set({ location: '', address: undefined });
	}


}