import { Injectable, computed, inject, signal } from "@angular/core";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonDateTimeField, CommonDateTimeFieldConfig, CommonGeneralFieldConfig, CommonMultiSelectField, CommonNumberField, CommonNumberFieldConfig, CommonOptionsFieldConfig, CommonPhoneField, CommonSelectField, CommonTextField, CommonTextFieldConfig, UrlService } from "@eforall/common";
import { FuncService } from '../../../../../services';
import { ApplicationService } from "../../application.service";

@Injectable({ providedIn: 'root' })
export class ApplicationAboutYouFormService {

	public readonly applicationService = inject(ApplicationService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);


	private participant = computed(() => {
		const application = this.applicationService.application();
		if (!application) return;
		const user = this.applicationService.app.data().user;
		return application.participants.find(participant => participant.personId == user.personId);
	});

	private readonly doingItFullTimeOptions = [
		{ value: 'Y', text: 'I work on this business full-time' },
		{ value: 'N', text: 'I work on this business part-time' }
	];

	public readonly doingItFullTime: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Involvement', options: this.doingItFullTimeOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.doingItFullTime || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const application = this.applicationService.application();
			const participant = application?.participants.find(participant => participant.personId == this.applicationService.app.data().user.personId);
			if (participant) {
				this.applicationService.app.setParticipantData({ ...participant, doingItFullTime: pendingValue });
				await this.func.participant.form.setDoingItFulltime({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, doingItFullTime: pendingValue });
			}
		},
		error: signal(''),
	};



	readonly title: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Your Title', min: 3, max: 50, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.title || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const participant = application?.participants.find(participant => participant.personId == this.applicationService.app.data().user.personId);
			if (participant) {
				await this.func.participant.form.setTitle({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, title: value });
				this.applicationService.app.setParticipantData({ ...participant, title: value });
			}
		},
		error: signal(''),
	};

	readonly firstName: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'First Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.app.data().user.firstName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setFirstName({ firstName: value });
				this.applicationService.app.setUserData({ ...user, firstName: value });
				this.applicationService.app.setParticipantData({ ...participant, firstName: value });
			}
		},
		error: signal(''),
	};

	readonly middleInit: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Middle Initial', min: 0, max: 1, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.app.data().user.middleInit || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setMiddleInit({ middleInit: value });
				this.applicationService.app.setUserData({ ...user, middleInit: value });
				this.applicationService.app.setParticipantData({ ...participant, middleInit: value });
			}
		},
		error: signal(''),
	};

	readonly lastName: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Last Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.applicationService.app.data().user.lastName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setLastName({ lastName: value });
				this.applicationService.app.setUserData({ ...user, lastName: value });
				this.applicationService.app.setParticipantData({ ...participant, lastName: value });
			}
		},
		error: signal(''),
	};


	readonly birthday: CommonDateTimeField = {
		config: signal<CommonDateTimeFieldConfig>({ label: 'Birthday', required: true, isSpanish: this.urlService.isSpanish(), type: 'birthday', includeTime: false }),
		actualValue: computed(() => {
			const participant = this.participant();
			const birthdayUTC = participant && participant.birthDay && participant.birthYear ? new Date(
				participant.birthYear,				// year e.g. 1900
				Math.floor(participant.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				participant.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
			return birthdayUTC;
		}),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {

			const application = this.applicationService.application();
			const data = this.applicationService.app.data();
			const participant = application?.participants.find(participant => participant.personId == data.user.personId);

			// 	await this.func.profile.form.setGenderOther({ birthday: value });
			// 	this.applicationService.app.setUserData(data.user);

		},
		error: signal(''),
	};



	private readonly gendersOptions = [
		{ value: 'F', text: 'Female' },
		{ value: 'M', text: 'Male' },
		{ value: 'N', text: 'Non-Binary' },
		{ value: 'O', text: 'Other' },
	];

	readonly gender: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Gender', options: this.gendersOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setGender({ gender: value });
				this.applicationService.app.setUserData({ ...user, gender: value });
				this.applicationService.app.setParticipantData({ ...participant, gender: value });
			}
		},
		error: signal(''),
	};


	readonly genderOther: CommonTextField = {
		config: signal({ label: 'Other Gender', min: 0, max: 45, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.genderOther || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant && user.gender == 'O') {
				await this.func.profile.form.setGenderOther({ genderOther: value });
				this.applicationService.app.setUserData({ ...user, genderOther: value });
				this.applicationService.app.setParticipantData({ ...participant, genderOther: value });
			}
		},
		error: signal(''),
	};

	private readonly racesOptions = [
		{ value: 'N', text: 'American Indian or Alaska Native' },
		{ value: 'A', text: 'Asian' },
		{ value: 'B', text: 'Black or African American' },
		{ value: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
		{ value: 'W', text: 'White' },
	];

	readonly races: CommonMultiSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race', options: this.racesOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setRaces({ races: value });
				this.applicationService.app.setUserData({ ...user, races: value });
				this.applicationService.app.setParticipantData({ ...participant, races: value });
			}
		},
		error: signal(''),
	};


	private readonly hispanicOptions = [
		{ value: 'Y', text: 'I am Hispanic or Latino' },
		{ value: 'N', text: 'I am NOT Hispanic or Latino' }
	];
	readonly hispanicOrLatino: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Hispanic Or Latino', options: this.hispanicOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setHispanic({ hispanic: pendingValue });
				this.applicationService.app.setUserData({ ...user, hispanic: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, hispanic: pendingValue });
			}
		},
		error: signal(''),
	};


	private readonly veteranOptions = [
		{ value: 'Y', text: 'I am a U.S. Veteran' },
		{ value: 'N', text: 'I am NOT a U.S. Veteran' }
	];
	readonly veteran: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Veteran', options: this.veteranOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setVeteran({ veteran: pendingValue });
				this.applicationService.app.setUserData({ ...user, veteran: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, veteran: pendingValue });
			}
		},
		error: signal(''),
	};


	private readonly immigrantOptions = [
		{ value: 'Y', text: 'I immigrated to the U.S.' },
		{ value: 'N', text: 'I am NOT an immigrant to the U.S.' }
	];
	readonly immigrant: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Immigrant', options: this.immigrantOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.immigrant || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setImmigrant({ immigrant: pendingValue });
				this.applicationService.app.setUserData({ ...user, immigrant: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, immigrant: pendingValue });
			}
		},
		error: signal(''),
	};



	private readonly nativeEnglishOptions = [
		{ value: 'Y', text: 'I am a Native English Speaker' },
		{ value: 'N', text: 'I am NOT a Native English Speaker' }
	];
	readonly nativeEnglish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native English Speaker', options: this.nativeEnglishOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.nativeEnglish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setNativeEnglish({ nativeEnglish: pendingValue });
				this.applicationService.app.setUserData({ ...user, nativeEnglish: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, nativeEnglish: pendingValue });
			}
		},
		error: signal(''),
	};


	private readonly nativeSpanishOptions = [
		{ value: 'Y', text: 'I am a native Spanish Speaker' },
		{ value: 'N', text: 'I am NOT a native Spanish Speaker' }
	];
	readonly nativeSpanish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native Spanish Speaker', options: this.nativeSpanishOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.nativeSpanish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setNativeSpanish({ nativeSpanish: pendingValue });
				this.applicationService.app.setUserData({ ...user, nativeSpanish: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, nativeSpanish: pendingValue });
			}
		},
		error: signal(''),
	};


	private readonly employmentStatusOptions = [
		{ value: 1, text: 'Unemployed' },
		{ value: 2, text: 'Underemployed' },
		{ value: 3, text: 'Employed' },
		{ value: 4, text: 'Retired' },
	];

	readonly employmentStatus: CommonSelectField<number> = {
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Employement Status', options: this.employmentStatusOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.employmentStatusId || 0),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (value && participant) {
				this.applicationService.app.setParticipantData({ ...participant, employmentStatusId: value });
				await this.func.participant.form.setEmploymentStatusId({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, employmentStatusId: value });
			}
		},


		error: signal(''),
	};

	private readonly headOfHouseholdOptions = [
		{ value: 'Y', text: 'I am Head of Household' },
		{ value: 'N', text: 'I am NOT Head of Household' }
	];

	readonly headOfHousehold: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Head of Household (according to IRS)', options: this.headOfHouseholdOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.headOfHousehold || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';

			const application = this.applicationService.application();
			const participant = application?.participants.find(participant => participant.personId == this.applicationService.app.data().user.personId);

			if (participant) {
				await this.func.participant.form.setHeadOfHousehold({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, headOfHousehold: pendingValue });
				this.applicationService.app.setParticipantData({ ...participant, headOfHousehold: pendingValue });
			}
		},

		error: signal(''),
	};



	readonly householdIncome: CommonNumberField = {
		config: signal<CommonNumberFieldConfig>({ label: 'Household Income (last year)', max: 100000000, required: true, isSpanish: this.urlService.isSpanish(), type: 'dollar', }),
		actualValue: computed(() => this.participant()?.householdIncome ?? undefined),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {

			const application = this.applicationService.application();
			const participant = application?.participants.find(participant => participant.personId == this.applicationService.app.data().user.personId);

			if (value != undefined && participant) {
				await this.func.participant.form.setHouseholdIncome({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, householdIncome: value });
				this.applicationService.app.setParticipantData({ ...participant, householdIncome: value });
			}
		},
		error: signal(''),
	};



	readonly phone: CommonPhoneField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Phone Number', required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.participant()?.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const application = this.applicationService.application();
			const user = this.applicationService.app.data().user;
			const participant = application?.participants.find(participant => participant.personId == user.personId);

			if (participant) {
				await this.func.profile.form.setPhone({ phone: value });
				this.applicationService.app.setUserData({ ...user, phone: value });
				this.applicationService.app.setParticipantData({ ...participant, phone: value });
			}
		},
		error: signal(''),
	};


	readonly address: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Home Address', isSpanish: false, required: true }),
		actualValue: computed(() => {
			const participant = this.participant();
			return { inUS: participant?.homeInUS || true, latitude: participant?.placeLatitude, longitude: participant?.placeLongitude, placeAddress: participant?.placeAddress || '', placeId: participant?.placeId, streetAddress: participant?.homeAddress || '', zipId: participant?.homeZipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			const user = this.applicationService.app.data().user;
			const application = this.applicationService.application();
			const participant = application?.participants.find(participant => participant.personId == user.personId);
			if (value && value.zipId && participant) {
				await this.func.participant.form.setHomeAddress({ applicationId: participant.applicationId, applicationParticipantId: participant.applicationParticipantId, address: value });


				this.applicationService.app.setParticipantData({
					...participant,
					homeInUS: value.inUS,
					homeZipId: value.zipId,
					homeAddress: value.streetAddress || '',
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});

				this.applicationService.app.setUserData({
					...user,
					inUS: value.inUS,
					zipId: value.zipId,
					address: value.streetAddress,
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});

			}
		},
		error: signal(''),
	};



	public aboutYouComplete = computed<boolean>(() => {

		const hasError = !!this.doingItFullTime.error()
			|| !!this.title.error()
			|| !!this.firstName.error()
			|| !!this.lastName.error()
			|| !!this.gender.error()
			|| !!this.hispanicOrLatino.error()
			|| !!this.races.error()
			|| !!this.veteran.error()
			|| !!this.immigrant.error()
			|| !!this.nativeEnglish.error()
			|| !!this.birthday.error()
			|| !!this.employmentStatus.error()
			|| !!this.headOfHousehold.error()
			|| !!this.householdIncome.error()
			|| !!this.phone.error()
			|| !!this.address.error()
			|| !!this.nativeSpanish.error();


		const participant = this.participant();

		const allValid = participant ? !!participant.doingItFullTime
			&& !!participant.employmentStatusId
			&& !!participant.headOfHousehold
			&& !!participant.title
			&& !!participant.homeZipId
			&& !!participant.placeId
			&& participant.householdIncome != undefined
			&& !!participant.firstName
			&& !!participant.lastName
			&& !!participant.birthDay && !!participant.birthYear
			&& !!participant.gender
			&& !!participant.races?.length
			&& !!participant.hispanic
			&& !!participant.immigrant
			&& !!participant.veteran
			&& !!participant.nativeEnglish
			&& !!participant.nativeSpanish
			&& !!participant.phone
			: false;


		return !hasError && allValid;

	});

}