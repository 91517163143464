import { Component, inject } from "@angular/core";
import { MobileLayoutService } from "./layout/mobile-layout.service";

@Component({
	selector: 'mobile-panel-part',
	standalone: true,
	template: `<div class="mobile-panel" [style.height.px]="layout.dimensions().contentHeight"><ng-content></ng-content></div>`,
	styles: [` 
		.mobile-panel {
			padding: 10px;
			border: 1px dotted #ccc;
		}
	`]
})
export class MobilePanelPart {

	public layout = inject(MobileLayoutService);

}