import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		NoSurveyOpen: computed(() => en() ? `No Survey open at this time` : `No Survey open at this time`),
		AccSessionSurvey: computed(() => en() ? `Accelerator Session Survey` : `Sesión de aceleración Survey`),
		AcceleratorSession: computed(() => en() ? `Accelerator Session` : `Sesión de aceleración`),
		OpenInLanguage: computed(() => en() ? `Open in Spanish` : `Abierto en ingles`),
		GoToCorrectLanguageMsg: computed(() => en() ? `Please open the survey in spanish. TBD` : `Please open the survey in english. TBD`),
		SessionSurveyAlreadySubmitted: computed(() => en() ? `Congratulations! You have already submitted this survey.` : `¡Felicidades! Ya ha enviado esta encuesta.`),
		TeamMembers: computed(() => en() ? `xxx` : `xxx`),


		MdSessionSurveyNotAttendee: computed(() => en()
			? 'You do not have access to this surevy as you are not an attendee for this session for {{acc-name}}. If you need assistance, reach out to the Program Manager at {{pm-email}}.'
			: 'No tiene acceso a este seguro ya que no es un asistente a esta sesión para {{acc-name}}. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.'
		),

		MdSessionSurveyNotOpenYet: computed(() => en()
			? 'The survey is not yet available. Please return in {{time-period}}. If you need assistance, reach out to the PM at {{pm-email}}.'
			: 'La encuesta aún no está disponible. Vuelve en {{time-period}}. Si necesita ayuda, comuníquese con el PM en {{pm-email}}.'
		),

		MdSessionSurveyClosed: computed(() => en()
			? 'The survey is no longer available, as of {{time-period}} ago. If you need assistance, reach out to the Program Manager at {{pm-email}}.'
			: 'La encuesta ya no está disponible desde hace {{time-period}}. Si necesita ayuda, comuníquese con el gerente del programa en {{pm-email}}.'
		),

		MdSessionSurveyOpen: computed(() => en()
			? 'Congratulations on attending this session. You have until {{close-date}} to fill out the survey.'
			: '¡Felicitaciones por asistir a esta sesión! Tiene hasta {{close-date}} para llenar esta encuesta.'
		),

		SurveyAnswerAllQuestionsMsg: computed(() => en()
			? 'Please answer all questions to be able to submit.'
			: 'Por favor conteste todas las preguntas para poder enviar.'
		),


		TopicLongName: <{ [index: string]: Signal<string> }>{
			'Accelerator Kick Off': computed(() => en() ? 'Accelerator Kick Off' : 'Bienvenida'),
			'Business Models and Customer Segments': computed(() => en() ? 'Business Models and Customer Segments' : 'Modelos de negocio y Segmentos de clientes'),
			'Connecting Customers to Value': computed(() => en() ? 'Connecting Customers to Value' : 'Conectando las necesidades del cliente con mi producto'),
			'Prioritizing Customers Testing Assumptions': computed(() => en() ? 'Prioritizing Customers and Testing Assumptions' : 'Priorización de clientes y validacion de supuestos'),
			'Testing Assumptions Through Surveys': computed(() => en() ? 'Testing Assumptions Through Surveys' : 'Prueba de supuestos a través de encuestas '),
			'Setting Goals and Working through Surveys': computed(() => en() ? 'Setting Goals and Working through Surveys' : 'Establecimiento de metas y elaboración de encuestas'),
			'Testing Profitability Quick Calculations': computed(() => en() ? 'Testing Profitability through Quick Calculations' : 'Cálculos rápidos para determinar ganancias'),
			'Checking In and Planning Ahead': computed(() => en() ? 'Checking In and Planning Ahead' : 'Revision del programa hasta ahora y planificación de los proximos meses'),
			'Exploring Pricing Strategies': computed(() => en() ? 'Exploring Pricing Strategies' : 'Exploración de estrategias de precios'),
			'Entrepreneur Tell All/Open Class': computed(() => en() ? 'Entrepreneur Tell All/Open Class' : 'Clase abierta- Sugerencias: Emprendedor Invitado o Herramientas en Línea/Google Suite, etc.'),
			'Progress Pitch': computed(() => en() ? 'Progress Pitch' : 'Presentación del progreso'),
			'Branding and Messaging': computed(() => en() ? 'Branding and Messaging' : 'Creación de la marca (Branding) y el mensaje'),
			'Forecasting Revenues and Costs': computed(() => en() ? 'Forecasting Revenues and Costs' : 'Prevision de costos e ingresos'),
			'Legal Issues of Business': computed(() => en() ? 'Legal Issues of Business' : 'Aspectos legales'),
			'Accounting': computed(() => en() ? 'Accounting' : 'Contabilidad'),
			'Insurance': computed(() => en() ? 'Insurance' : 'Seguros'),
			'Reaching Customers through Websites': computed(() => en() ? 'Reaching Customers through Websites' : 'Llegar a los clientes a través de paginas web'),
			'Marketing and Social Media': computed(() => en() ? 'Marketing and Social Media' : 'Comercialización (Marketing) y redes sociales'),
			'Asking for Money': computed(() => en() ? 'Asking for Money' : 'Acceso a capital'),
			'Presentation Skills': computed(() => en() ? 'Presentation Skills' : 'Aptitudes de presentación (hablar en público)'),
			'Marketing through Media Outlets': computed(() => en() ? 'Marketing through Media Outlets' : 'Marketing a través de los medios'),
			'Purpose and Impact of Your Business': computed(() => en() ? 'Exploring the Purpose and Impact of Your Business' : 'Explorando el propósito y el impacto de su negocio'),
			'Selling to your Customer': computed(() => en() ? 'Selling to your Customer' : 'Vender a su cliente'),
			'Practice Presentations': computed(() => en() ? 'Practice Presentations' : 'Práctica para la Presentación Final'),
			'Final Presentation Practice': computed(() => en() ? 'Final Presentation Practice' : 'Práctica para la Presentación Final'),
		},

		questions: {
			NoSurveyQuestionsMsg: computed(() => en()
				? 'This topic does not have any survey questions.'
				: 'Este tema no tiene preguntas de encuesta.'
			),
			IWasAbleToEasilyAttendThisSession: computed(() => en()
				? 'I was able to easily attend this session.'
				: 'Me fue fácil asistir a esta clase.'
			),
			IReceivedAllInformationOfSession: computed(() => en()
				? 'I received all the information I needed ahead of the session.'
				: 'Recibí toda la información necesaria antes de la clase.'
			),
			TheSessionWasWellFacilitated: computed(() => en()
				? 'The session was well facilitated by EforAll staff.'
				: 'La clase estuvo bien facilitada por el personal de EparaTodos.'
			),
			SessionLogisticsOptional: computed(() => en()
				? 'What else would you like to say about the session logistics? (optional)'
				: '¿Tiene algún comentario acerca de la logística/organización de esta clase? (opcional)'
			),
			IWasPresentForThisTopic: computed(() => en()
				? 'I was present for this topic'
				: 'Estuve presente en este tema'
			),
			ThisTopicIsImportantForMyBusiness: computed(() => en()
				? 'This topic is important for my business / non-profit.'
				: 'Este tema es importante para mi negocio / organización sin fines de lucro.'
			),
			IWillUseThisTopicNextThreeMonths: computed(() => en()
				? 'I will use this topic knowledge within the next three months.'
				: 'Usaré el conocimiento adquirido en este tema dentro de los próximos tres meses.'
			),
			IHaveBetterUnderstandingOfTopic: computed(() => en()
				? 'I have a better understanding of this topic and can more confidently apply it.'
				: 'Tengo mejor entendimiento de este tema y puedo aplicarlo con más confianza en mi emprendimiento.'
			),
			ThereWasAdequateTimeDedicatedToTopic: computed(() => en()
				? 'There was adequate time dedicated to this topic.'
				: 'El tiempo dedicado a este tema fue el adecuado.'
			),
			SayAboutTopicOptional: computed(() => en()
				? 'What else would you like to say about this topic? (optional)'
				: '¿Tiene algún comentario acerca de este tema visto en clase? (opcional)'
			),
			IWasPresentForThisSpecialist: computed(() => en()
				? 'I was present for this specialist'
				: 'Estuve presente para este especialista'
			),
			TheSpecialistWasProfessional: computed(() => en()
				? 'The specialist was professional.'
				: 'El especialista se comportó profesionalmente durante la clase.'
			),
			TheSpecialistWasKnowledgeable: computed(() => en()
				? 'The specialist was knowledgeable about this topic.'
				: 'El especialista tiene buen conocimiento del tema.'
			),
			TheSpecialistEffectivelyTaughtTheTopic: computed(() => en()
				? 'The specialist effectively taught the topic.'
				: 'El especialista enseñó de manera efectiva el tema de la clase'
			),
			SayAboutSpecialistOptional: computed(() => en()
				? 'What else would you like to say about this specialist? (optional)'
				: '¿Tiene algún comentario acerca de este especialista? (opcional)'
			),
		},
	}
}