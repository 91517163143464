<div class="d-flex flex-column gap-2">
	
	<common-wistia-player [video]="video()" (videoWatchedVectorChanged)="videoWatchedVectorChanged.emit($event)"></common-wistia-player>


	<div class="white-box">
		<common-thumbs-up-down-rating [rating]="rating()"
			(updateRating)="updateVideoRating($event)"></common-thumbs-up-down-rating>
	</div>

	<div ngbAccordion [closeOthers]="true">
		<div ngbAccordionItem [collapsed]="true">
			<h2 ngbAccordionHeader>
				<button ngbAccordionButton class="p-2">Transcript</button>
			</h2>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody class="p-2">
					<ng-template>
						@if(video().transcript){
						<markdown [data]="video().transcript"></markdown>
						}
						@else {
						<div class="text-center color-gray">No transcript available</div>
						}
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>