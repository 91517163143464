import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonButtonRowWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { FuncService } from '../../services';
import { getLabels } from './surveys.page.labels';


@Component({
	selector: 'surveys-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		FormsModule,
		MobileListWidget,
	],
	templateUrl: './surveys.page.html'
})

export class SurveysPage implements OnInit {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private util = inject(UtilityService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	public labels = getLabels(this.urlService);
	public readonly surveysList = signal<MobileListWithSubItem[]>([]);


	public infoContent = computed(() => {
		const count = this.surveysList().length;
		return count > 0 ? this.util.text.singularOrPluralWithCount(count, 'Open Survey', 'Open Surveys') : `No Open Surveys`;
	});

	constructor() {
		this.frame.setUrlMetadata({
			url: `/surveys`,
			backUrl: `/`,
			pageName: computed(() => `Surveys`),
			headerText: computed(() => `Surveys`),
		});
	}


	async ngOnInit() {

		const accSessions = await this.func.surveys.get();

		const list = accSessions.reduce((a: MobileListWithSubItem[], accSession) => {
			a.push({
				icon: accSession.accSessionSurveyResponseId ? `fa-circle color-green` : `fa-circle color-eforall-blue`,
				iconStyle: 'fa-solid',
				text: this.labels.AccSessionSurvey() ?? '',
				subText: accSession.accSessionSurveyResponseId ? 'Complete' : 'Closes: ' + this.util.date.formatUTC(accSession.toolEndUTC, 'MM/DD/YYYY', 'H:MM AM EST', this.urlService.isSpanish() ? 'es-US' : 'en-US'),
				callback: () => {
					if (this.urlService.languageId() !== accSession.accLanguageId) {
						this.urlService.setLanguage(accSession.accLanguageId);
					}
					const path = `surveys/acc-session/${accSession.accSessionId}`;

					this.router.navigate([this.urlService.withCurrentLanguage(path)]);
				},
			});

			return a;

		}, []);

		this.surveysList.set(list);

	}

}