import { RouteReuseStrategy, DetachedRouteHandle, ActivatedRouteSnapshot } from '@angular/router';

export class CustomReuseStrategy implements RouteReuseStrategy {
	
	store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
		return;
	}

	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return false;
	}

	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		return true; // Always detach components
	}

	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		return false;
	}

	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		return null; // Retrieve if stored
	}
}