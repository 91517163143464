import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonButtonRowWidget, CommonTextField, CommonTextFieldConfig, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { FuncService } from '../../../services';

@Component({
	selector: 'applications-join-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonButtonRowWidget,
		FormsModule,
	],
	templateUrl: './join.page.html'
})

export class ApplicationJoinPage {
	private frame = inject(MobileFrameService);
	private func = inject(FuncService);
	private urlService = inject(UrlService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);

	readonly code: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Code', min: 9, max: 9, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async () => {
		},
		error: signal(''),
	};

	public pendingCode = signal<string>('');


	private readonly labels = computed(() => {
		return this.code.config().isSpanish ? {
			missingRequired: `Required field`,
			minLength: `Code must be at least 12 letters long`,
			invalidPattern: `Code must match xxx-xxx-xxx pattern`,
			notValid: `The code "${this.pendingCode()}" is Invalid`,
		} : {
			missingRequired: `Required field`,
			minLength: `Code must be at least 12 letters long`,
			invalidPattern: `Code must match xxx-xxx-xxx pattern`,
			notValid: `The code "${this.pendingCode()}" is Invalid`,
		};

	});

	private readonly validated = signal(false);
	public readonly errorToShow = computed(() => {
		if (this.validated() && this.code.error()) return this.code.error();
		return '';
	});


	constructor() {
		this.frame.setUrlMetadata({
			url: '/applications/join',
			backUrl: '/applications',
			pageName: computed(() => `Join`),
			headerText: computed(() => `Join`),
		});
	}

	onChange(value: string) {
		this.pendingCode.set(value);
		this.validated.set(false);
	}

	async validate() {
		this.validated.set(true);
		const labels = this.labels();
		let error = this.checkForError();
		if (!error) {
			const code = this.pendingCode().split('-').join('');
			const codeDetails = await this.func.pendingParticipant.validateInviteCode({ code });
			if (!codeDetails) error = labels.notValid;
		}

		this.code.error.set(error);
		if (!error) this.router.navigate([this.pendingCode().toLowerCase()], { relativeTo: this.route })
	}

	checkForError(): string {
		const value = this.pendingCode();
		const labels = this.labels();
		const pattern = /^[a-zA-Z]{3}-[a-zA-Z]{3}-[a-zA-Z]{3}$/;

		if (value.length == 0) return labels.missingRequired;
		if (value.length && !value.match(pattern)) return labels.invalidPattern;
		if (value.length < 11) return labels.minLength;
		return '';
	}


}