import { Component } from "@angular/core";

@Component({
	selector: 'mobile-flyout-header-part',
	standalone: true,
	template: `<div class="mobile-flyout-header" [style.height.px]="50"><ng-content></ng-content></div>`,
	styles: [` 
		.mobile-flyout-header {
			display: flex;
		}
	`]
})
export class MobileFlyoutHeaderPart {

}