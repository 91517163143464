<mobile-page-part>

	<div class="info-content">
		@if(accTeamCohort(); as accTeamCohort){
		<h3>{{util.text.singularOrPluralWithCount(accTeamCohort.teams.length, labels.common.Venture() ,
			labels.common.Ventures())}}</h3>
		}
		@else {
		<small>{{ labels.common.NotAuthorized() }}</small>
		}
	</div>


	@if(accTeamCohort(); as accTeamCohort){
	<div class="white-box">
		<h4>{{accTeamCohort.accName}}</h4>
		<div>{{ labels.ClassesStart() }}: {{getDate(accTeamCohort.classStartUTC)}}</div>
		<div>{{ labels.MeetingTimes() }}: {{accTeamCohort.meetingTimes}}</div>
		<div>{{ labels.common.ProgramManager() }}: {{accTeamCohort.pmName}}</div>
		<div>{{ labels.common.Contact() }}: <a target='_blank'
				href='mailto:{{accTeamCohort.pmEmail}}'>{{accTeamCohort.pmEmail}}</a></div>
	</div>

	@if(list(); as list){

	<mobile-list [items]="list.myTeam" [header]="labels.MyTeam()"></mobile-list>


	<mobile-list [items]="list.teams" [header]="labels.MyCohort()"></mobile-list>
	}
	}
	@else {
	<common-message [alertType]="'warning'">Not a team yet. TBD</common-message>
	}

</mobile-page-part>