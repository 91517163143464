import { inject, Injectable, signal } from "@angular/core";
import { FuncService } from "../../services";
import { Agreement } from "@interfaces";

@Injectable({ providedIn: 'root' })
export class AgreementService {

	private func = inject(FuncService);

	public agreements = signal<Agreement[]>([]);
	private readAgreements = signal(false);



	public async getAndSetAgreements() {
		if (!this.readAgreements()) {
			const agreements = await this.func.agreements.get();
			this.agreements.set(agreements);
			this.readAgreements.set(true);
		}
	}


	public async signAgreement(agreementVersionId: number) {
		const agreements = await this.func.agreements.addAgreementSignature({ agreementVersionId });
		this.agreements.set([...agreements]);
	}

}