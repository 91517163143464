import { NgClass } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonAddressFieldWidget, CommonButtonColumnWidget, CommonMessageWidget, CommonNumberFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from '../application.service';
import { ApplicationYourBusinessFormService } from './your-business-form.service';

@Component({
	selector: 'application-your-business-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonMessageWidget,
		CommonTextFieldWidget,
		CommonSelectFieldWidget,
		CommonNumberFieldWidget,
		CommonPhoneFieldWidget,
		CommonAddressFieldWidget,
		CommonButtonColumnWidget,
		NgClass,
	],
	templateUrl: './your-business.page.html'
})

export class ApplicationYourBusinessPage implements OnInit {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);
	public form = inject(ApplicationYourBusinessFormService);

	public instructions = computed(() => 'Please tell us about your current business or business idea. No individual/personal information will be shared with any external third party without your authorization. EforAll will only use aggregated (non-personally identifiable) data for fundraising purposes so our nonprofit can continue to offer our programs at no cost.');

	instructionClicked = signal(false);

	public primaryBtn = computed<'next-step' | 'instructions' | 'none'>(() => {
		if (this.applicationService.aboutBusinessComplete()) {
			return 'next-step';
		}

		const isClicked = this.instructionClicked();
		if (!isClicked) return 'instructions';
		return 'none';
	});

	constructor() {
		this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], undefined);
		this.frame.setUrlMetadata({
			url: `/applications/${this.applicationService.applicationId()}/your-business`,
			backUrl: `/applications/${this.applicationService.applicationId()}`,
			pageName: computed(() => `About Your Business`),
			headerText: computed(() => `About Your Business`),
		});
	}


	ngOnInit() {
		const applicationId = this.applicationService.applicationId();
		const key = `application-${applicationId}-your-business`;
		const isClicked = this.applicationService.getInstructionKey(key);
		this.instructionClicked.set(isClicked);

	}

	openInstructions() {
		const applicationId = this.applicationService.applicationId();
		if (applicationId) {
			const key = `application-${applicationId}-your-business`;

			const stored = this.applicationService.getInstructionKey(key);
			if (!stored) {
				this.applicationService.setInstructionKey(key, 'clicked');
				this.instructionClicked.set(true);
			}
			this.applicationService.openInstructionFlyout(this.instructions());
		}

	}

}