@if(quiz(); as quiz){
<div class="d-flex flex-column gap-4">

	@if(quiz.attemptSubmittedUTC){
	<common-message>
		<div>
			<div>You got {{quiz.percentCorrect}}% correct.</div>
			<div>Attempt {{quiz.countOfAttempts}} of 3.</div>
		</div>
	</common-message>
	}

	@for(question of questions(); track $index; let idx = $index){
	<div>
		<h4>
			{{urlService.commonLabels.form.Question()}} {{idx+ 1}}: {{question.label}}
		</h4>

		<div class="d-flex flex-column gap-2">
			@if(quiz.attemptSubmittedUTC){
			<common-message [alertType]="question.correctChoiceId !== question.field.actualValue() ? 'error':'success'">
				<div class="d-flex flex-row justify-content-between">
					@if(question.correctChoiceId !== question.field.actualValue()){
					<span>Incorrect.</span>
					}
					@else {
					<span>Correct.</span>
					}
					<a class="link ms-1" (click)="openFlyoutMessage(question.correctChoiceReason)">Click here to read
						why</a>
				</div>
			</common-message>
			}

			<common-radio-field [field]="question.field"></common-radio-field>
		</div>
	</div>
	}

	@if(!quiz.attemptSubmittedUTC){
	<common-button-row [center]="true">
		<button class="btn btn-large btn-primary" [disabled]="!checkIfCanSubmit()" (click)="submit()">Submit</button>
	</common-button-row>
	}
	@else if(quiz.countOfAttempts !== 3) {
	<common-button-row [center]="true">
		<button class="btn btn-large btn-primary" (click)="addAttempt()">Try again</button>
		<a class="link" routerLink="../../level/{{quiz.courseLevelStructureId}}">Need to review? Go back to Overview</a>
	</common-button-row>
	}
</div>
}