<div class="mobile-layout-part">

	<div class="flyout-container"></div>

	<mobile-scrollable-content>

		<div class="relative-container">
			<div class="header-auto-show">
				<mobile-toolbar-part></mobile-toolbar-part>
			</div>
		</div>

		<div class="page-content" (scroll)="onPageContentScroll($event)">

			<div class="relative-container">
				<div class="card header-swoop">
					<div class="card-overlay bg-highlight opacity-95"></div>
					<div class="card-bg preload-img overlay-image"></div>
				</div>
			</div>

			<div class="container main-content" [style.min-height.px]="layout.dimensions().contentHeight - 20">
				<mobile-toolbar-part></mobile-toolbar-part>
				<ng-content></ng-content>
			</div>

			<div class="relative-container">
				<div class="footer-swoop card overlay-image">
					<div class="card-overlay bg-highlight opacity-95"></div>
				</div>
			</div>
		</div>

		<div class="relative-container">
			<mobile-nav-bar-part></mobile-nav-bar-part>
		</div>
	</mobile-scrollable-content>
</div>