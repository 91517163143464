import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonIconTextWidget, UrlService, UtilityService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService } from '@eforall/mobile';
import { GoalComment } from '@interfaces';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'goal-comments-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		RouterModule,
	],
	templateUrl: './comments-flyout.part.html',
	styleUrl: './comments-flyout.part.scss'
})
export class GoalCommentsFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);
	public util = inject(UtilityService);
	public urlService = inject(UrlService);


	public readonly comments = input.required<GoalComment[]>();


	close() {
		this.activeOffcanvas.close();
	}
}