import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonAddressFieldWidget, CommonPhoneFieldWidget, CommonSelectFieldWidget, CommonTextFieldWidget, UrlService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ProfileBasicInfoFormService } from './basic-info-form.service';
import { getLabels } from './basic-info.page.labels';


@Component({
	selector: 'profile-basic-info-page',
	standalone: true,
	imports: [
		MobilePagePart,
		FormsModule,
		CommonTextFieldWidget,
		CommonAddressFieldWidget,
		CommonPhoneFieldWidget,
		CommonSelectFieldWidget,
	],
	templateUrl: './basic-info.page.html'
})

export class ProfileBasicInfoPage {

	private readonly frame = inject(MobileFrameService);
	public readonly form = inject(ProfileBasicInfoFormService);
	private readonly urlService = inject(UrlService);
	private readonly labels = getLabels(this.urlService);


	constructor() {
		this.frame.setUrlMetadata({
			url: '/profile/info',
			backUrl: '/profile',
			pageName: computed(() => this.labels.Info()),
			headerText: computed(() => this.labels.Info()),
		});
	}

}