<mobile-page-part>

	<div class="info-content">
		<h3>{{infoContent()}}</h3>
	</div>


	<mobile-list [items]="surveysList()" [noItemsMessage]="labels.NoSurveyOpen()"></mobile-list>



</mobile-page-part>