import { IndustryId } from "@interfaces";

/**
 * Return the Icon value for a given IndustryId
 */
export function getIconForIndustry(industryId: IndustryId): string {

	if (industryId == IndustryId.Administrative_Support) return `fa-user-headset`;
	if (industryId == IndustryId.Agriculture) return `fa-farm`;
	if (industryId == IndustryId.Animals) return `fa-paw`;
	if (industryId == IndustryId.Apparel_Accessories) return `fa-tshirt`;
	if (industryId == IndustryId.Arts_Culture) return `fa-paint-brush`;
	if (industryId == IndustryId.Automotive) return `fa-cars`;
	if (industryId == IndustryId.Beauty_Cosmetics) return `fa-pencil-paintbrush`;
	if (industryId == IndustryId.Childcare_FamilyServices) return `fa-child`;
	if (industryId == IndustryId.Cleaning) return `fa-hand-sparkles`;
	if (industryId == IndustryId.Coaching_Mentoring) return `fa-people-carry`;
	if (industryId == IndustryId.Community_Support) return `fa-hand-holding-heart`;
	if (industryId == IndustryId.CreativeServices_Multimedia) return `fa-photo-video`;
	if (industryId == IndustryId.Education) return `fa-chalkboard-teacher`;
	if (industryId == IndustryId.Entertainment_Recreation) return `fa-umbrella-beach`;
	if (industryId == IndustryId.Environment) return `fa-globe-americas`;
	if (industryId == IndustryId.Event_Planning) return `fa-calendar-day`;
	if (industryId == IndustryId.Finance_Accounting) return `fa-calculator`;
	if (industryId == IndustryId.Food_Beverage) return `fa-burger-soda`;
	if (industryId == IndustryId.Health_Wellness) return `fa-heartbeat`;
	if (industryId == IndustryId.Hospitality_Accommodations) return `fa-concierge-bell`;
	if (industryId == IndustryId.Lawncare) return `fa-hand-sprinkler`;
	if (industryId == IndustryId.Legal) return `fa-balance-scale`;
	if (industryId == IndustryId.Manufacturing) return `fa-tools`;
	if (industryId == IndustryId.Marketing) return `fa-rss`;
	if (industryId == IndustryId.Medical) return `fa-notes-medical`;
	if (industryId == IndustryId.Other) return `fa-hyphen`;
	if (industryId == IndustryId.Personal_Relationships) return `fa-user-friends`;
	if (industryId == IndustryId.Philanthropy) return `fa-hand-holding-usd`;
	if (industryId == IndustryId.Photography_Media) return `fa-camera-alt`;
	if (industryId == IndustryId.Property_Management) return `fa-building`;
	if (industryId == IndustryId.Retail) return `fa-shopping-cart`;
	if (industryId == IndustryId.Salon_Spa_PersonalCare) return `fa-hand-heart`;
	if (industryId == IndustryId.Technology) return `fa-microchip`;
	if (industryId == IndustryId.Trades_Electrician_Plumber) return `fa-user-hard-hat`;
	if (industryId == IndustryId.Travel_Tourism) return `fa-plane-departure`;

	return '';
}