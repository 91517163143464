import { AppData, UserApplication } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { FuncData } from '@interfaces';

/**
 * Convert raw app data into structures that can more
 * easily used the app (e.g. maps)
  */
export function processData(data: FuncData, util: UtilityService, isSpanish: boolean): AppData {

	const companyMap = util.array.toMap(data.businesses, b => b.companyId);

	const userApplications: UserApplication[] = data.userApplications.reduce((a: UserApplication[], application) => {

		const applicant = application.participants.find(participant => participant.personId == application.application.personId)!;
		a.push({
			...application,
			applicant,
			deadline: application.application.applStartUTC ? util.date.formatDate(new Date((application.application.applStartUTC + application.application.applDuration * 60) * 1000), 'MMM D, YYYY (DOW)', isSpanish ? 'es-US' : 'en-US') : 'Passed Deadline',
			business: companyMap[application.application.companyId]!,
			name: `${application.application.siteName} ${application.application.programInstance} ${application.application.programName}`,
		});
		return a;
	}, []);

	return {
		asOfUtc: data.asOfUtc,
		user: data.user,
		businesses: data.businesses,
		userApplications
	};
}