import { NgClass } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonColumnWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from '../application.service';
import { ApplicationAboutYouPart } from './part/application-about-you.part';


@Component({
	selector: 'application-about-you-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonColumnWidget,
		NgClass,
		ApplicationAboutYouPart,
	],
	templateUrl: './about-you.page.html'
})

export class ApplicationAboutYouPage {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);


	public name = computed(() => {
		const user = this.applicationService.app.data().user;
		return `${user.firstName} ${user.lastName}`;
	});


	constructor() {
		this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], undefined);
		this.frame.setUrlMetadata({
			url: `/applications/${this.applicationService.applicationId()}/you`,
			backUrl: `/applications/${this.applicationService.applicationId()}`,
			pageName: computed(() => `About You`),
			headerText: computed(() => `About You`),
		});
	}
}