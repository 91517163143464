import { Component, computed, input, output } from '@angular/core';
import { CommonThumbsUpDownRatingWidget, CommonWistiaPlayerWidget, ThumbsUpDown, ThumbsUpDownRating } from '@eforall/common';
import { CourseActivityVideo } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';


@Component({
	selector: 'learning-video-part',
	standalone: true,
	imports: [
		MarkdownModule,
		CommonThumbsUpDownRatingWidget,
		NgbAccordionModule,
		CommonWistiaPlayerWidget,

	],
	providers: [provideMarkdown()],
	templateUrl: './video.part.html'
})

export class LearningVideoPart {

	public readonly video = input.required<CourseActivityVideo>();
	public setRating = output<ThumbsUpDownRating>();
	public readonly videoWatchedVectorChanged = output<number[]>();

	public rating = computed<ThumbsUpDown>(() => {
		const video = this.video();

		return {
			text: video.ratingQuestion,
			rating: video.videoRating as ThumbsUpDownRating,
		};

	});



	public updateVideoRating(rating: ThumbsUpDownRating) {
		this.setRating.emit(rating);
	}


}