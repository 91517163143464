import { computed, Signal } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,


		messages: <{ [index: string]: Signal<string | undefined> }>{
			'noProgramInYourAreaMsg': computed(() => en()
				? 'We currently do not offer any programing in your area. Please contact info@eforall.org if you are interested in bringing EforAll to your community.'
				: 'Usted no es elegible para solicitar al programa que seleccionó. Para más información por favor contáctenos en informacion@eparatodos.org.'
			),
			'noProgramsAvailableMsg': computed(() => en()
				? 'We do not have any programs accepting applications in your area at this time. Please check eforall.org/programs regularly for updated program information.'
				: 'Actualmente no hay programas que acepten solicitudes en su área. Por favor revise regularmente nuestra página web - eparatodos.org/programas para ver información actualizada acerca de nuestros programas.'
			),
			'selectedProgramNotAvailableMsg': computed(() => en()
				? 'You are not eligible to apply for the program you selected. For more information, you may contact us at info@eforall.org.'
				: 'Usted no es elegible para solicitar al programa que seleccionó. Para más información por favor contáctenos en info@eparatodos.org.'
			),
			'talkToPMMessage': computed(() => en()
				? 'Our records indicate that you have already participated in an Accelerator program. Please contact the Site listed to see if you qualify to participate again.'
				: 'Nuestros registros indican que usted ya participó en un Acelerador de Negocios. Por favor póngase en contacto con el sitio que aparece aquí para ver si califica para participar nuevamente.'
			),
			'alreadyAcceptedCannotApplyAnyMsg': computed(() => en()
				? 'Our records indicate you have already participated in an Accelerator program. You are only allowed to enroll in one program so we can serve as many aspiring entrepreneurs as possible in a community.'
				: 'Nuestros registros indican que usted ya participó en un Acelerador de Negocios. Solo permitimos participar en un solo Acelerador de Negocios para que otras personas en la comunidad puedan tomar ventaja de nuestros programas.'
			),
			'hasPendingApplicationAccMsg': computed(() => en()
				? 'You have already submitted an application which is being reviewed. You are unable to apply for another Accelerator program at this time.'
				: 'Usted sometio una solicitud que esta siendo revisada en estos momentos, por esta razon no puede llenar otra solicitud para el Acelerador de Negocios.'
			),
			'hasOpenApplicationAccTransferMsg': computed(() => en()
				? 'You currently have a pending Accelerator application. Please view the application to finish applying or contact the Site to transfer your application to a different Accelerator.'
				: 'Actualmente tiene una solicitud de Acelerador de Negocios abierta, por favor revisela para finalizarla o comuníquese con el sitio para que la transfieran a una comunidad diferente.'
			),
			'alreadyAcceptedCannotApplyPicMsg': computed(() => en()
				? 'Congratulations! Our records indicate you have already won award(s) at a Pitch Contest! You are only eligible to participate in one Pitch Contest so you may not apply again.'
				: '¡Felicidades, nuestros registros indican que ya ha recibido premios en uno de los concursos Lanza Tu Idea! Por esta razón no puede volver a solicitar para participar en otro concurso.'
			),
			'alreadyAcceptedOngoingPicMsg': computed(() => en()
				? 'You have already been selected to participate in a Pitch Contest! You cannot apply to any other Pitch Contests at this time.'
				: '¡Ya ha sido escogido para participar en un concurso Lanza Tu Idea! En estos momentos no puede llenar otra solicitud para un concurso.'
			),
			'hasPendingApplicationPicMsg': computed(() => en()
				? 'An application is already under review for a Pitch Contest. You cannot apply to another Pitch Contest at this time.'
				: 'Una solicitud suya esta siendo revisada en estos momentos, por esta razón no puede llenar otra solicitud para otro concurso en estos momentos.'
			),
			'hasOpenApplicationPicTransferMsg': computed(() => en()
				? 'You already have a pending Pitch Contest application. Please view the application to finish applying or contact the Site listed to transfer your application to a different Pitch Contest.'
				: 'Actualmente tiene una solicitud para un concurso Lanza Tu Idea abierta. Por favor finalice su solicitud o póngase en contacto con el sitio que aparece aquí para que transfiera su solicitud a otro concurso disponible para usted.'
			),
			'openApplicationTransferDialogMsg': computed(() => en()
				? "You have an open application. If you would like to apply to another Community's program, please contact the Site and ask them to transfer your application."
				: 'Nuestros registros indican que tiene una soliciud abierta. Si prefiere solicitar a este programa en otra comunidad, por favor póngase en contacto con el sitio y pídale que transfiera su solicitud.'
			),
			'staffSupportMessage': computed(() => en()
				? 'If you have questions about this information or need help with your application, please contact the Site listed. Thank you!'
				: 'Si tiene alguna pregunta acerca de está información o necesita ayuda con su solicitud, por favor póngase en contacto con el sitio que aparece aquí. ¡Gracias!'
			),

		},
	}
}