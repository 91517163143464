import { ApplicationStatusId } from "@interfaces";

export const OPEN_STATUS = [ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending, ApplicationStatusId.TeamPending];

export interface PendingApplication {
	name: string,
	siteName: string,
	deadline: string,
	language: string,
	siteEmail: string,
	applicationId: number,
}


export interface EligibleApplication {
	name: string,
	siteName: string,
	deadline: string,
	language: string,
	siteEmail: string,
}


export interface BusinessForApplication {
	companyId: number,
	companyName: string,
	companyTypeId: number,
	industryId: number
	title: string,
	/**
 *  Name of the applicant if the companyId already has an application for the programInstance
 */
	priorApplicant?: string,
}