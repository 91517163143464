<mobile-page-part>

	<div class="info-content">
		<h3>{{calendarService.date()}}</h3>
	</div>



	<mobile-list [items]="calendarService.onGoingList()" header="Ongoing Events"
		noItemsMessage="No Ongoing Events"></mobile-list>


	<mobile-list [items]="calendarService.upcomingList()" header="Upcoming Events"
		noItemsMessage="No Upcoming Events"></mobile-list>

	<mobile-list [items]="calendarService.upcomingList()" header="Past Events"
		noItemsMessage="No Past Events"></mobile-list>

</mobile-page-part>