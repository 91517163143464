import { Component, inject, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonButtonColumnWidget, CommonButtonRowWidget, CommonMessageWidget, UrlService } from '@eforall/common';
import { CourseActivityWorkbook } from '@interfaces';
import { getLabels } from './workbook.part.labels';


@Component({
	selector: 'learning-workbook-part',
	standalone: true,
	imports: [
		CommonButtonRowWidget,
		FormsModule,
		CommonButtonColumnWidget,
		CommonMessageWidget,
	],
	templateUrl: './workbook.part.html'
})

export class LearningWorkbookPart {

	public readonly workbook = input.required<CourseActivityWorkbook>();
	public readonly workbookCompleted = output<boolean>();
	private UrlService = inject(UrlService);
	public labels = getLabels(this.UrlService);


	public onSelectionChange(value: boolean) {
		if (value) this.workbookCompleted.emit(true);
	}

}