import { Injectable, computed, inject, signal } from '@angular/core';
import { UrlService, UtilityService } from '@eforall/common';
import { Application, ApplicationParticipant, Company, FuncData, FuncUserApplication, User } from '@interfaces';
import { FuncService } from '../func/func.service.gen';
import { EMPTY_DATA } from './empty-data';
import { funcDataEquality } from './func-data-equality';
import { processData } from './process-data';
import { UserApplication } from '@app-interfaces';


@Injectable({ providedIn: 'root' })
export class AppService {

	private readonly util = inject(UtilityService);
	private readonly func = inject(FuncService);
	private readonly urlService = inject(UrlService);

	private readonly _data = signal<FuncData>(EMPTY_DATA, { equal: funcDataEquality })
	public readonly data = computed(() => processData(this._data(), this.util, this.urlService.isSpanish()));


	public async loadData(): Promise<void> {
		this._data.set(await this.func.getAllData());
	}


	public async clearData(): Promise<void> {
		this._data.set(EMPTY_DATA);
	}

	public setUserData(user: User) {
		//
		// Incremented by just 1 sec so it knows the user changed as we are poking the value in.
		//
		const asOfUtc = this._data().asOfUtc + 1;
		this._data.set({ ...this._data(), asOfUtc, user });
	}

	public setApplicationData(application: Application) {
		const asOfUtc = this._data().asOfUtc + 1;
		const userApplications = [...this._data().userApplications];
		const applIndex = userApplications.findIndex(appl => appl.application.applicationId == application.applicationId);
		if (applIndex !== -1 && userApplications[applIndex]) userApplications[applIndex]!.application = { ...application };

		this._data.set({ ...this._data(), asOfUtc, userApplications });
	}

	public setBusinessData(business: Company) {
		const asOfUtc = this._data().asOfUtc + 1;
		const businesses = [...this._data().businesses];
		const businessIndex = businesses.findIndex(company => business.companyId == company.companyId);
		if (businessIndex !== -1 && businesses[businessIndex]) businesses[businessIndex] = { ...business };

		this._data.set({ ...this._data(), asOfUtc, businesses: [...businesses] });
	}

	public setParticipantData(participant: ApplicationParticipant) {
		const asOfUtc = this._data().asOfUtc + 1;
		const userApplications = [...this._data().userApplications];
		const application = userApplications.find(appl => appl.application.applicationId == participant.applicationId);
		const participantIndex = application ? application.participants.findIndex(participant => participant.applicationParticipantId == participant.applicationParticipantId) : -1;

		if (participantIndex !== -1 && application?.participants[participantIndex]) application.participants[participantIndex] = { ...participant };

		this._data.set({ ...this._data(), asOfUtc, userApplications });
	}


	public async update<T>(action: (payload: T) => Promise<FuncData>, payload: T): Promise<void> {
		this._data.set(await action(payload));
	}
}