@if(header() && (!!items().length || !!noItemsMessage())){
<h4>{{header()}}</h4>
}

@if(items().length){

<div class="mobile-list">

	@for(item of items(); track $index;)
	{
	@if(item.subItems && item.subItems.length)
	{
	<div ngbAccordion>
		<!-- @for (subItem of item.subItems; track item) { -->
		<div ngbAccordionItem [collapsed]="true">

			<div ngbAccordionHeader>
				<button ngbAccordionButton class="btn btn-flat d-flex justify-content-left align-items-center gap-3">
					<i class='fa-fw fs-5 {{item.iconStyle}} {{item.icon}}'></i>
					<div class="d-flex flex-column text-start">
						<span>{{item.text}}</span>
						@if(item.subText)
						{
						<small>{{item.subText}}</small>
						}
					</div>
				</button>
			</div>
			<div ngbAccordionCollapse>
				<div ngbAccordionBody>
					@for(subItem of item.subItems; track $index;)
					{
					<mobile-list-link-part [item]="subItem"></mobile-list-link-part>

					}
				</div>
			</div>
		</div>

	</div>
	}
	@else {
	<mobile-list-link-part [item]="item" [height]="height()"></mobile-list-link-part>
	}
	}
</div>
}
@else
{
@if(noItemsMessage()){<div class="text-center color-gray"><i>{{noItemsMessage()}}</i></div>}
}