
// The main mobile area doesn't have standard SM,MS,LG,XL breakpoints
// because it is generally not responsive (always portrait mobile).
// However, we do need breakpoints to define when to switch between
// snapping to the edge and when to draw a fake phone chrome,
// and also when to add extra side panels.


// These must align with the values in mobile-variables.scss


/**
 * Less than BREAKPOINT_TABLET is snapped to mobile device.
 * Else, less than BREAKPOINT_COMPUTER is shown in phone chrome without side panel.
 * Else, less than BREAKPOINT_WIDE_COMPUTER is shown in phone chrome WITH one side panel.
 * Else, it is shown in phone chrome WITH two side panels.
 */
export const BREAKPOINT_TABLET = 480;
/**
 * Less than BREAKPOINT_TABLET is snapped to mobile device.
 * Else, less than BREAKPOINT_COMPUTER is shown in phone chrome without side panel.
 * Else, less than BREAKPOINT_WIDE_COMPUTER is shown in phone chrome WITH one side panel.
 * Else, it is shown in phone chrome WITH two side panels.
 */
export const BREAKPOINT_COMPUTER = 925;
/**
 * Less than BREAKPOINT_TABLET is snapped to mobile device.
 * Else, less than BREAKPOINT_COMPUTER is shown in phone chrome without side panel.
 * Else, less than BREAKPOINT_WIDE_COMPUTER is shown in phone chrome WITH one side panel.
 * Else, it is shown in phone chrome WITH two side panels.
 */
export const BREAKPOINT_WIDE_COMPUTER = 1350;
