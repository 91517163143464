import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonButtonRowWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobilePagePart } from '@eforall/mobile';
import { ExampleButtonsPage } from "../../examples/buttons/buttons.page";

@Component({
	selector: 'courses-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
		ExampleButtonsPage
	],
	templateUrl: './courses.page.html'
})

export class LearningCoursesPage {
	private frame = inject(MobileFrameService);

	constructor() {
		this.frame.setUrlMetadata({
			url: '/learning/courses',
			backUrl: '/learning',
			pageName: computed(() => `Courses`),
			headerText: computed(() => `Courses`),
		});
	}

}