import { JsonPipe } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonProgressBarWidget, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { LearningCourseService } from '../course.service';
import { CourseActivity, CourseLevel, CourseVersion } from '@interfaces';

@Component({
	selector: 'course-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
		CommonMessageWidget,
		JsonPipe,
		NgbAccordionModule,
		CommonProgressBarWidget,
	],
	templateUrl: './course.page.html'
})

export class LearningCoursePage implements OnInit {
	private frame = inject(MobileFrameService);
	public courseService = inject(LearningCourseService);
	public util = inject(UtilityService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	public progressBar = [false, false, false, false, false, false];



	public course = computed(() => {
		const course = this.courseService.course();

		if (course) {

			return {
				enrollmentDate: this.util.date.formatUTC(course.enrollmentDate, 'MMM D, YYYY', 'No Time', this.courseService.urlService.isSpanish() ? 'es-US' : 'en-US'),
				activities: course.activities,
				title: course.title,
				completedPercentage: this.calculateCompletionScore(course),
				activityList: this.getMobileList(course.activities),
				levels: course.levels.map(level1 => {
					return {
						courseLevelStructureId: level1.courseLevelStructureId,
						levelType: level1.levelType,
						levelTitle: level1.levelTitle,
						activitiesList: this.getMobileList(level1.activities),
						children: level1.children.map(level2 => {
							return {
								courseLevelStructureId: level2.courseLevelStructureId,
								levelType: level2.levelType,
								levelTitle: level2.levelTitle,
								activitiesList: this.getMobileList(level2.activities),
								children: level2.children.map(level3 => {
									const hasActivities = !!level3.activities.length;

									return {
										courseLevelStructureId: level3.courseLevelStructureId,
										levelType: level3.levelType,
										levelTitle: level3.levelTitle,
										activitiesStartedCount: this.getActivitiesStartedCount(level3.activities),
										activitiedCompletedMessage: this.getActivitiesCompletedMessage(level3.activities),
										hasActivities,
										percentComplete: this.getActivitiesPercentComplete(level3.activities),
										pendingActivityId: this.getPendingActivityId(level3.activities),
										activities: level3.activities.map(activity => {
											return {
												courseActivityStructureId: activity.courseActivityStructureId,
												courseActivityType: activity.courseActivityType,
												activityTitle: activity.activityTitle,
												activityOptional: activity.activityOptional,
											};
										})

									}
								}),

							}
						}),
					}
				}),


			}
		}

		return undefined;


	});

	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];

		this.frame.setUrlMetadata({
			url: `/learning/courses/${courseVersionId}`,
			backUrl: '/learning/courses',
			pageName: computed(() => `Course`),
			headerText: computed(() => `Course`),
		});

		await this.courseService.loadDataAndSetCourseVersion(courseVersionId);
	}


	public getMobileList(activities: CourseActivity[]): MobileListWithSubItem[] {
		if (!activities || !activities.length) return [];

		return activities.map(activity => {
			const url = `learning/courses/${this.courseService.courseVersionId()}/activity/${activity.courseActivityStructureId}`;
			return {
				icon: activity.response && activity.response.completedUTC ? 'fa-circle color-green' : 'fa-circle color-lightgray',
				iconStyle: 'fa-solid',
				text: `${activity.courseActivityType}: ${activity.activityTitle}`,
				subText: `${activity.response ?
					activity.response.completedUTC ? 'Completed' : 'Incomplete'
					: 'Not started'} ${activity.activityOptional ? "(Optional)" : ""}`,
				callback: () => {
					this.router.navigate([this.courseService.urlService.withCurrentLanguage(url)]);
				}
			};
		});
	}




	private getActivitiesCompletedMessage(activities: CourseActivity[]): string {
		if (!activities.length) return 'No Activities';

		const completedActivities = activities.filter(activity => activity.response && !activity.activityOptional && activity.response.completedUTC).length;
		return `${completedActivities} of ${activities.filter(activity => !activity.activityOptional).length} Required Activities Completed`;
	}

	private getActivitiesPercentComplete(activities: CourseActivity[]): number {
		if (!activities.length) return 0;

		const completedActivities = activities.filter(activity => activity.response && !activity.activityOptional && activity.response.completedUTC).length;
		return Math.round((completedActivities / activities.filter(activity => !activity.activityOptional).length) * 100);
	}

	private getActivitiesStartedCount(activities: CourseActivity[]): number {
		const startedActivities = activities.filter(activity => activity.response && activity.response.startUTC).length;
		return startedActivities;
	}

	private getPendingActivityId(activities: CourseActivity[]): number | undefined {
		const pendingActivity = activities.find(activity => !activity.activityOptional && (!activity.response || (activity.response && !activity.response.completedUTC)));
		return pendingActivity?.courseActivityStructureId;
	}


	private calculateCompletionScore(courseVersion: CourseVersion): number {
		// Helper function to recursively count completed and total non-optional activities
		function countActivities(levels: CourseLevel[]): { completed: number, total: number } {
			let completed = 0;
			let total = 0;

			for (const level of levels) {
				// Count only non-optional activities at the current level
				for (const activity of level.activities) {
					if (!activity.activityOptional) {  // Skip optional activities
						if (activity.response?.completedUTC) {
							completed++;
						}
						total++;
					}
				}

				// Recursively count activities in child levels
				const childCounts = countActivities(level.children);
				completed += childCounts.completed;
				total += childCounts.total;
			}

			return { completed, total };
		}

		// Start counting from the top-level of the course version
		const { completed, total } = countActivities(courseVersion.levels);

		// Calculate the percentage and normalize it to a scale of 0-10
		const completionPercentage = total > 0 ? (completed / total) * 10 : 0;

		// Return the result rounded
		return Math.round(completionPercentage);
	}

}