@if(applyService.continueClicked())
{

<div class="d-flex flex-column gap-3">

	<table class="table table-md table-bordered align-middle mb-0">
		<tr>
			<td class="p-1">Home address</td>
			<td class="p-1">{{applyService.form.homeAddress.actualValue()!.placeAddress}}</td>
		</tr>
		@if(applyService.form.businessLocation.actualValue() === 'Y' &&
		applyService.form.businessAddress.actualValue()?.placeAddress)
		{
		<tr>
			<td class="p-1">Business address</td>
			<td class="p-1">{{applyService.form.businessAddress.actualValue()?.placeAddress}}</td>
		</tr>
		}
	</table>

	@for(message of applyService.eligiblePrograms().messageLabels; track $index;){
	@if(labels.messages[message]){
	<common-message alertType='warning'>
		<markdown [data]="labels.messages[message]!()"></markdown>
	</common-message>
	}
	}


	<!-- Show pending Applications -->
	@if(applyService.pendingAppls().length)
	{
	<div class="d-flex flex-column gap-2">
		<h4>My Pending Applications</h4>
		@for(appl of applyService.pendingAppls(); track appl.applicationId;)
		{
		<div class="white-box">
			<h4>{{appl.name}}</h4>
			<div>Location: {{appl.siteName}}</div>
			<div>Deadline: {{appl.deadline}}</div>
			<div>Language: {{appl.language}}</div>
			<div>Contact: <a target='_blank' href='mailto:{{appl.siteEmail}}'>{{appl.siteEmail}}</a></div>
			<common-button-column>
				<button class="btn btn-standard btn-large"
					[routerLink]="applyService.urlService.withCurrentLanguage('applications/'+appl.applicationId)">Open</button>
			</common-button-column>
		</div>
		}
	</div>
	}

	<!-- Show open application button -->
	@if(applyService.hasOpenApplications())
	{
	<common-button-row [center]="true">
		<button class="btn btn-standard btn-large"
			[routerLink]="applyService.urlService.withCurrentLanguage('applications')">View Open Applications</button>
	</common-button-row>
	}



	<!-- Show eligible programs -->
	@if(applyService.eligiblePrograms().eligiblePrograms.length)
	{
	<div class="d-flex flex-column gap-2">
		<h4>Here are the programs in your area</h4>
		@for(appl of applyService.eligiblePrograms().eligiblePrograms; track $index;)
		{
		<div class="white-box">
			<h4>{{appl.programInstance}} {{appl.programName}}</h4>
			<div>Location: {{appl.siteName}}</div>
			<div>Deadline: {{appl.applDeadline}}</div>
			@if(appl.accId){
			<div>Class Start Date: {{appl.classStartDate}}</div>
			}
			<div>Language: {{appl.language}}</div>
			<div>Contact: <a target='_blank' href='mailto:{{appl.contactEmail}}'>{{appl.contactEmail}}</a></div>
			<common-button-column>
				<button class="btn btn-standard btn-large" (click)="applyService.applyForAProgram(appl)">
					@if(urlService.languageId() === appl.languageId) {
					{{labels.button.Apply()}}
					}
					@else {
					{{labels.button.ApplyInLanguage()}}

					}
				</button>
			</common-button-column>
		</div>
		}
	</div>
	}


</div>
}