import { computed, inject, Injectable, signal } from "@angular/core";
import { UrlService, UtilityService } from "@eforall/common";
import { DomainDataService, FuncService } from '../../../services';
import { AccTeam } from '@interfaces';
import { MobileListWithSubItem } from "@eforall/mobile";

@Injectable({ providedIn: 'root' })
export class MyTeamService {

	public func = inject(FuncService);
	public util = inject(UtilityService);
	public domain = inject(DomainDataService);
	public urlService = inject(UrlService);



	private readonly loaded = signal<boolean>(false);
	public accTeam = signal<AccTeam | undefined>(undefined);


	public team = computed<{
		data: {
			name: string,
			orgType: string,
			industry: string,
			offerings: string,
			status: string,
			canEdit: boolean,
			applicationId: number,
		},
		entrepreneurs: MobileListWithSubItem[],
		mentors: MobileListWithSubItem[],
	} | undefined>(() => {
		const accTeam = this.accTeam();
		const domain = this.domain.data();

		if (accTeam) {
			return {
				data: {
					industry: domain.industryMap[accTeam.industryId]?.label || '',
					name: `${accTeam.companyName}${accTeam.shortName ? ' (' + accTeam.shortName + ')' : ''}`,
					offerings: accTeam.offerings == 'Both' ? 'Products & Services' : accTeam.offerings,
					orgType: domain.companyTypeMap[accTeam.companyTypeId]?.label || '',
					status: accTeam.status,
					applicationId: accTeam.applicationId,
					canEdit: accTeam.canEdit,
				},
				entrepreneurs: accTeam.entrepreneurs.map(entrepreneur => {
					return {
						icon: 'fa-user',
						iconStyle: 'fa-solid',
						text: entrepreneur.name,
						subText: entrepreneur.email,
					}
				}),
				mentors: accTeam.mentors.map(mentors => {
					return {
						icon: 'fa-user',
						iconStyle: 'fa-solid',
						text: mentors.name,
						subText: mentors.email,
					}
				}),
			}

		}

		return undefined;
	});


	async loadData() {
		if (!this.loaded()) {
			this.reset();
			const team = await this.func.cohort.getTeam();
			this.accTeam.set(team);
		}
	}



	reset() {
		this.accTeam.set(undefined);
	}



	public setTeamBusinessData(team: AccTeam) {
		this.accTeam.set({ ...team });
	}
}