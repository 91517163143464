import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		ClassesStart: computed(() => en() ? `Classes Start` : `{{ ES: Classes Start }}`),
		MeetingTimes: computed(() => en() ? `Meeting Times` : `{{ ES: Meeting Times }}`),
		MyCohort: computed(() => en() ? `My Cohort` : `{{ ES: My Cohort }}`),
		MyTeam: computed(() => en() ? `My Team` : `{{ ES: My Team }}`),
		TeamMember: computed(() => en() ? `Team Member` : `{{ ES: Team Member }}`),
		TeamMembers: computed(() => en() ? `Team Members` : `{{ ES: Team Members }}`),

	};
}