@if(frame.config(); as config)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<common-icon-text iconStyle='fa-solid' icon="fa-info-circle" text="Confirm Submission" spacing="large"
			iconSize="large">
		</common-icon-text>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		@if(applicationService.application(); as application)
		{
		@if(application.application.applicationStatusId > 3)
		{
		<div>
			TODO: Message will be added soon.<br>
			Thank you for submitting your application etc etc
		</div>
		}
		@else if(applicationService.stepsCompleted() !== 4)
		{
		<div>
			TODO: Message will be added soon.<br>
			Display message - Cannot submit. Please complete all the steps before submitting.
		</div>
		}
		@else if(applicationService.stepsCompleted() === 4 && application.canSubmit)
		{
		<div>
			TODO: Message will be added soon.<br>
			Can submit application
			<button class="btn btn-primary" (click)="applicationService.submit()">Submit</button>
		</div>
		}

		}
	</mobile-flyout-body-part>
</mobile-flyout-frame>
}