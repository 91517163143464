import { computed } from '@angular/core';
import { UrlService } from '@eforall/common';


export function getLabels(urlService: UrlService) {

	const en = urlService.isEnglish;

	return {

		...urlService.commonLabels,

		CompletedWorkbookCheckbox: computed(() => en() ? 'I have completed this activity in the workbook' : '{{ ES: I have completed this activity in the workbook }}'),
	};
}