import { FuncData } from '@interfaces';


/**
 * Compare two auth state objects and determine if they are equivalent
 */
export function funcDataEquality(s1: FuncData, s2: FuncData) {

	if (s1.asOfUtc !== s2.asOfUtc) return false;

	return true;
}