<mobile-page-part>

	<div class="info-content">
		<h3>This is H3 header</h3>
	</div>

	<h2>This is H1, H2 header</h2>
	<h3>This is H3 header</h3>
	<h4>This is H4, H5, H6 header</h4>


	<p>
		This is paragraph text.
	</p>



	<div class="white-box">
		<h3>This is H3 header</h3>
		<p>
			This is paragraph text. Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti sunt, ipsum
			voluptates placeat a recusandae illum. Perspiciatis, officia ratione sunt similique odit vel neque suscipit
			quaerat commodi delectus, veritatis corrupti?
		</p>
	</div>



	<common-message alertType="info" [closeable]="true">This is an info message</common-message>
	<common-message alertType="success" [closeable]="true">This is a success message</common-message>
	<common-message alertType="warning">This is a warning message. Lorem, ipsum dolor sit amet consectetur adipisicing
		elit. Aliquam possimus libero mollitia quae velit! Culpa quisquam nihil amet sequi, hic itaque eius rem animi
		reprehenderit nulla adipisci laudantium, officiis exercitationem?</common-message>
	<common-message alertType="error">This is an error message</common-message>


<div ngbAccordion [closeOthers]="true">
	@for (item of items; track item) {
	<div ngbAccordionItem [collapsed]="item !== 'First'">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>{{ item }}</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
					wolf moon
					officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
					Brunch 3
					wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch
					et.
					Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad
					vegan
					excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth
					nesciunt
					you probably haven't heard of them accusamus labore sustainable VHS.
				</ng-template>
			</div>
		</div>
	</div>
	}
</div>




</mobile-page-part>