import { Component, OnInit, computed, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonTextFieldWidget } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { ApplicationService } from '../application.service';
import { ApplicationQuestionFormService } from './question-form.service';

@Component({
	selector: 'application-question-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonTextFieldWidget,
		CommonButtonRowWidget,
		CommonMessageWidget
	],
	templateUrl: './question.page.html',
	styles: [`
		div.question-answer-content {
			min-height: 25em;
			h4 {
				min-height: 4em;
				align-content: center;
			}
		}
	`],
})

export class ApplicationQuestionPage implements OnInit {
	private frame = inject(MobileFrameService);
	public applicationService = inject(ApplicationService);
	private route = inject(ActivatedRoute);
	public form = inject(ApplicationQuestionFormService);

	public questionIndex = computed<number>(() => {
		const selectedQuestion = this.applicationService.selectedQuestion();
		if (selectedQuestion) return this.applicationService.answers().map(answer => answer.question.questionId).indexOf(selectedQuestion.question.questionId);
		return 0;
	});


	public nextStepDisabled = computed(() => {
		const hasError = !!this.form.question().error();
		const pendingValue = this.form.question().pendingValue();
		const hasValue = pendingValue === null ? !!this.form.question().actualValue() : !!pendingValue;
		return hasError || (this.form.question().config().min > 0 && !hasValue);
	});


	async ngOnInit() {
		await this.applicationService.setApplicationIdAndQuestion(+this.route.snapshot.params['applicationId'], +this.route.snapshot.params['questionId']);

		this.frame.setUrlMetadata({
			url: `/applications/${this.applicationService.applicationId()}/questions/${this.applicationService.selectedQuestion()?.question.questionId}`,
			backUrl: `/applications/${this.applicationService.applicationId()}`,
			pageName: computed(() => `Application Questions`),
			headerText: computed(() => `Application Questions`),
		});

		for (const answer of this.applicationService.answers()) {
			const url = `/applications/${this.applicationService.applicationId()}/questions/${answer.question.questionId}`;
			const pageName = answer.question.text;

			this.frame.setUrlMetadata({
				url,
				backUrl: `/applications/${this.applicationService.applicationId()}`,
				pageName: computed(() => pageName),
				headerText: computed(() => `Application Questions`),
			});
		}

		this.form.question().error.set('');
	}

}