<mobile-page-part>

	<div class="info-content">
		<h3>{{applicationInfoContent()}}</h3>
	</div>

	<mobile-list [items]="applicationsList()"></mobile-list>



	<h3>Got an invite code?</h3>
	<common-button-column>
		<button class="btn btn-primary btn-large" [routerLink]="urlService.withCurrentLanguage('applications/join')">Join an Application</button>
	</common-button-column>

</mobile-page-part>