import { Injectable, Signal, computed, effect, inject, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UrlService } from '@eforall/common';
import { EMPTY_MOBILE_FRAME_CONFIG, MobileFrameConfig } from './mobile-frame-config';


interface UrlMetadata {

	/** The URL of the current page */
	readonly url: string,
	/** The URL to navigate to if the back button is pressed */
	readonly backUrl: string,
	/** The name of the page displayed in the browser title and navigation */
	readonly pageName: Signal<string>,
	/** The text displayed in the header swoop */
	readonly headerText: Signal<string>,

}

@Injectable({ providedIn: 'root' })
export class MobileFrameService {

	private router = inject(Router);
	public urlService = inject(UrlService);
	private title = inject(Title);


	private readonly _urlDataMap = signal<{ [index: string]: UrlMetadata }>({});


	public urlData = computed<UrlMetadata>(() => {

		const url = this.urlService.withoutLanguage(this.urlService.url() ?? '');
		const map = this._urlDataMap();

		return map[url] ?? {
			url,
			backUrl: url,
			pageName: computed(() => url),
			headerText: computed(() => `UNDEFINED PAGE`),
		};
	});


	private _config = signal<Signal<MobileFrameConfig>>(signal(EMPTY_MOBILE_FRAME_CONFIG));


	public config = computed(() => {
		const _config = this._config();
		return _config();
	});


	constructor() {
		effect(() => {
			const data = this.urlData();
			const title = data?.pageName() ?? 'Staff Home';
			this.title.setTitle(title);
		});
	}


	public initialize(config: Signal<MobileFrameConfig>) {
		this._config.set(config);
	}


	navigateUp(metadata: UrlMetadata) {
		const backUrl = this.urlService.withCurrentLanguage(metadata.backUrl);
		this.router.navigate([backUrl]);
	}


	/**
	 * Register a page url and related information.
	 */
	public setUrlMetadata(metadata: UrlMetadata) {
		const map = { ...this._urlDataMap() };
		map[metadata.url] = metadata;
		this._urlDataMap.set(map);
	}


	/**
	 * Change the header text at the top of the page. This does
	 * not change the pageName that is shown in browser history.
	 */
	public setHeader(header: string) {
		const oldData = this.urlData();
		const newData: UrlMetadata = {
			url: oldData.url,
			backUrl: oldData.backUrl,
			pageName: oldData.pageName,
			headerText: computed(() => header)
		};

		this.setUrlMetadata(newData);
	}
}