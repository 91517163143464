import { Injectable, computed, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { AddressData, CommonAddressField, CommonAddressFieldConfig, CommonDateTimeField, CommonDateTimeFieldConfig, CommonGeneralFieldConfig, CommonMultiSelectField, CommonNumberField, CommonNumberFieldConfig, CommonOptionsFieldConfig, CommonPhoneField, CommonSelectField, CommonTextField, CommonTextFieldConfig, UrlService, UtilityService } from "@eforall/common";
import { AppService, FlyoutService, FuncService } from '../../../../services';

@Injectable({ providedIn: 'root' })
export class ApplicationJoinCodeFormService {

	private readonly func = inject(FuncService);
	public readonly urlService = inject(UrlService);
	private readonly app = inject(AppService);
	private readonly util = inject(UtilityService);
	private readonly router = inject(Router);
	private flyoutService = inject(FlyoutService);

	private values = signal<{
		title: string,
		address: AddressData | undefined,
		employmentStatusId: number,
		headOfHousehold: string,
		householdIncome: number | undefined,
		doingItFullTime: string,
	}>({
		title: '',
		address: undefined,
		employmentStatusId: 0,
		headOfHousehold: '',
		householdIncome: undefined,
		doingItFullTime: ''
	});

	private readonly doingItFullTimeOptions = [
		{ value: 'Y', text: 'I work on this business full-time' },
		{ value: 'N', text: 'I work on this business part-time' }
	];

	public readonly doingItFullTime: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Involvement', options: this.doingItFullTimeOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().doingItFullTime),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, doingItFullTime: value });
		},
		error: signal(''),
	};



	readonly title: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Your Title', min: 3, max: 50, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().title),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, title: value });
		},
		error: signal(''),
	};

	readonly firstName: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'First Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.firstName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setFirstName({ firstName: value });
			this.app.setUserData({ ...user, firstName: value });
		},
		error: signal(''),
	};

	readonly middleInit: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Middle Initial', min: 0, max: 1, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.middleInit || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setMiddleInit({ middleInit: value });
			this.app.setUserData({ ...user, middleInit: value });
		},
		error: signal(''),
	};

	readonly lastName: CommonTextField = {
		config: signal<CommonTextFieldConfig>({ label: 'Last Name', min: 1, max: 20, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.lastName || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setLastName({ lastName: value });
			this.app.setUserData({ ...user, lastName: value });
		},
		error: signal(''),
	};


	readonly birthday: CommonDateTimeField = {
		config: signal<CommonDateTimeFieldConfig>({ label: 'Birthday', required: true, isSpanish: this.urlService.isSpanish(), type: 'birthday', includeTime: false }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return user.birthDay && user.birthYear ? new Date(
				user.birthYear,				// year e.g. 1900
				Math.floor(user.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				user.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			).getTime() / 1000 : undefined;
		}),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {

			const user = this.app.data().user;
			// if (data.user.birthDay !== value) {
			// 	data.user.birthday = value;
			// 	this.app.setUserData(data.user);

			// 	await this.func.profile.form.setGenderOther({ birthday: value });
			// }
		},
		error: signal(''),
	};



	private readonly gendersOptions = [
		{ value: 'F', text: 'Female' },
		{ value: 'M', text: 'Male' },
		{ value: 'N', text: 'Non-Binary' },
		{ value: 'O', text: 'Other' },
	];

	readonly gender: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Gender', options: this.gendersOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.gender || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const user = this.app.data().user;
			await this.func.profile.form.setGender({ gender: value });
			this.app.setUserData({ ...user, gender: value });

		},
		error: signal(''),
	};


	readonly genderOther: CommonTextField = {
		config: signal({ label: 'Other Gender', min: 0, max: 45, fixCase: true, multiLine: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.genderOther || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {

			const user = this.app.data().user;
			if (user.gender == 'O') {

				await this.func.profile.form.setGenderOther({ genderOther: value });
				this.app.setUserData({ ...user, genderOther: value });
			}

		},
		error: signal(''),
	};

	private readonly racesOptions = [
		{ value: 'N', text: 'American Indian or Alaska Native' },
		{ value: 'A', text: 'Asian' },
		{ value: 'B', text: 'Black or African American' },
		{ value: 'P', text: 'Native Hawaiian or Other Pacific Islander' },
		{ value: 'W', text: 'White' },
	];

	readonly races: CommonMultiSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Race', options: this.racesOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.races || []),
		pendingValue: signal<string[] | null>(null),
		saving: signal(false),
		save: async (value: string[]) => {
			const user = this.app.data().user;
			await this.func.profile.form.setRaces({ races: value });
			this.app.setUserData({ ...user, races: value });
		},
		error: signal(''),
	};


	private readonly hispanicOptions = [
		{ value: 'Y', text: 'I am Hispanic or Latino' },
		{ value: 'N', text: 'I am NOT Hispanic or Latino' }
	];
	readonly hispanicOrLatino: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Hispanic Or Latino', options: this.hispanicOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.hispanic || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setHispanic({ hispanic: pendingValue });
			this.app.setUserData({ ...user, hispanic: pendingValue });
		},
		error: signal(''),
	};


	private readonly veteranOptions = [
		{ value: 'Y', text: 'I am a U.S. Veteran' },
		{ value: 'N', text: 'I am NOT a U.S. Veteran' }
	];
	readonly veteran: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Veteran', options: this.veteranOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.veteran || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setVeteran({ veteran: pendingValue });
			this.app.setUserData({ ...user, veteran: pendingValue });
		},
		error: signal(''),
	};


	private readonly immigrantOptions = [
		{ value: 'Y', text: 'I immigrated to the U.S.' },
		{ value: 'N', text: 'I am NOT an immigrant to the U.S.' }
	];
	readonly immigrant: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Immigrant', options: this.immigrantOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.immigrant || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setImmigrant({ immigrant: pendingValue });
			this.app.setUserData({ ...user, immigrant: pendingValue });
		},
		error: signal(''),
	};



	private readonly nativeEnglishOptions = [
		{ value: 'Y', text: 'I am a Native English Speaker' },
		{ value: 'N', text: 'I am NOT a Native English Speaker' }
	];
	readonly nativeEnglish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native English Speaker', options: this.nativeEnglishOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeEnglish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeEnglish({ nativeEnglish: pendingValue });
			this.app.setUserData({ ...user, nativeEnglish: pendingValue });
		},
		error: signal(''),
	};


	private readonly nativeSpanishOptions = [
		{ value: 'Y', text: 'I am a native Spanish Speaker' },
		{ value: 'N', text: 'I am NOT a native Spanish Speaker' }
	];
	readonly nativeSpanish: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Native Spanish Speaker', options: this.nativeSpanishOptions, required: false, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.nativeSpanish || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const pendingValue = value as 'N' | 'Y';
			const user = this.app.data().user;
			await this.func.profile.form.setNativeSpanish({ nativeSpanish: pendingValue });
			this.app.setUserData({ ...user, nativeSpanish: pendingValue });
		},
		error: signal(''),
	};


	private readonly employmentStatusOptions = [
		{ value: 1, text: 'Unemployed' },
		{ value: 2, text: 'Underemployed' },
		{ value: 3, text: 'Employed' },
		{ value: 4, text: 'Retired' },
	];

	readonly employmentStatus: CommonSelectField<number> = {
		config: signal<CommonOptionsFieldConfig<number>>({ label: 'Employement Status', options: this.employmentStatusOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().employmentStatusId),
		pendingValue: signal<number | null>(null),
		saving: signal(false),
		save: async (value: number) => {
			const values = this.values();
			this.values.set({ ...values, employmentStatusId: value });
		},

		error: signal(''),
	};

	private readonly headOfHouseholdOptions = [
		{ value: 'Y', text: 'I am Head of Household' },
		{ value: 'N', text: 'I am NOT Head of Household' }
	];

	readonly headOfHousehold: CommonSelectField<string> = {
		config: signal<CommonOptionsFieldConfig<string>>({ label: 'Head of Household (according to IRS)', options: this.headOfHouseholdOptions, required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.values().headOfHousehold),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const values = this.values();
			this.values.set({ ...values, headOfHousehold: value });
		},


		error: signal(''),
	};



	readonly householdIncome: CommonNumberField = {
		config: signal<CommonNumberFieldConfig>({ label: 'Household Income (last year)', max: 100000000, required: true, isSpanish: this.urlService.isSpanish(), type: 'dollar', }),
		actualValue: computed(() => this.values().householdIncome),
		pendingValue: signal<number | undefined | null>(null),
		saving: signal(false),
		save: async (value: number | undefined) => {
			const values = this.values();
			this.values.set({ ...values, householdIncome: value });
		},

		error: signal(''),
	};



	readonly phone: CommonPhoneField = {
		config: signal<CommonGeneralFieldConfig>({ label: 'Phone Number', required: true, isSpanish: this.urlService.isSpanish() }),
		actualValue: computed(() => this.app.data().user.phone || ''),
		pendingValue: signal<string | null>(null),
		saving: signal(false),
		save: async (value: string) => {
			const user = this.app.data().user;
			await this.func.profile.form.setPhone({ phone: value });
			this.app.setUserData({ ...user, phone: value });
		},
		error: signal(''),
	};


	readonly address: CommonAddressField = {
		config: signal<CommonAddressFieldConfig>({ label: 'Home Address', isSpanish: false, required: true }),
		actualValue: computed(() => {
			const user = this.app.data().user;
			return { inUS: user.inUS, latitude: user.placeLatitude, longitude: user.placeLongitude, placeAddress: user.placeAddress, placeId: user.placeId, streetAddress: user.address, zipId: user.zipId, };
		}),
		pendingValue: signal<AddressData | undefined | null>(null),
		saving: signal(false),
		save: async (value: AddressData | undefined) => {

			if (value && value.zipId) {
				const user = this.app.data().user;
				await this.func.profile.form.setAddress({ address: value });
				this.app.setUserData({
					...user,
					inUS: value.inUS,
					zipId: value.zipId,
					address: value.streetAddress,
					placeAddress: value.placeAddress,
					placeId: value.placeId,
					placeLatitude: value.latitude,
					placeLongitude: value.longitude,
				});
			}
		},
		error: signal(''),
	};




	public canJoin = computed<boolean>(() => {
		const user = this.app.data().user;

		return !!this.doingItFullTime.actualValue()
			&& this.employmentStatus.actualValue() != 0
			&& !!this.headOfHousehold.actualValue()
			&& this.householdIncome != undefined
			&& !!this.title.actualValue()
			&& !!user.firstName
			&& !!user.lastName
			&& !!user.birthDay && !!user.birthYear
			&& !!user.gender
			&& !!user.races?.length
			&& !!user.hispanic
			&& !!user.immigrant
			&& !!user.veteran
			&& !!user.nativeEnglish
			&& !!user.nativeSpanish
			&& !!user.phone
			&& !!user.zipId;
	});



	public async joinApplication(code: string) {
		const canJoin = this.canJoin();
		if (!canJoin) {
			const message = `Please complete all the required fields inorder to join the application. TBD`
			this.flyoutService.showMarkdownMessage(message, 'Join Application');
		}
		else {

			const participantDetails: {
				title: string,
				address: AddressData,
				employmentStatusId: number,
				headOfHousehold: string,
				householdIncome: number,
				doingItFullTime: string,
			} = {
				title: this.title.actualValue(),
				address: this.address.actualValue()!,
				employmentStatusId: this.employmentStatus.actualValue(),
				headOfHousehold: this.headOfHousehold.actualValue(),
				householdIncome: this.householdIncome.actualValue()!,
				doingItFullTime: this.doingItFullTime.actualValue(),
			}
			const applicationId = await this.func.participant.addByCode({ code: code.split('-').join(''), participantDetails });

			if (applicationId) this.router.navigate(['applications', applicationId]);
		}

	}



	public openInstructionFlyout(instruction: string) {
		this.flyoutService.showMarkdownMessage(instruction, 'Instructions');
	}

}