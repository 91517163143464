import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonButtonRowWidget, CommonMessageWidget, CommonProgressBarWidget } from '@eforall/common';
import { MobileFrameService, MobileListWidget, MobileListWithSubItem, MobilePagePart } from '@eforall/mobile';
import { CourseLevel } from '@interfaces';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { LearningCourseService } from '../course.service';
import { FlyoutService } from '../../../services';

interface Child {
	name: string,
	type: string,
	description: string,
	subText: string,
	courseLevelStructureId: number,
	additionalDescription?: {
		type: string,
		levels: string[],
	},
	progress?: {
		text: string,
		percentage: number,
	}
}


interface LevelDetails {
	courseLevelStructureId: number,
	name: string, description: string, infoContent: string, activities: MobileListWithSubItem[],
	childrenType?: string,
	children: Child[] | undefined,
	nextLevelUrl: string,
}

@Component({
	selector: 'level-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonButtonRowWidget,
		RouterLink,
		MobileListWidget,
		CommonMessageWidget,
		NgbAccordionModule,
		CommonProgressBarWidget,
		CommonButtonRowWidget
	],
	templateUrl: './level.page.html'
})

export class LearningLevelPage implements OnInit {
	private frame = inject(MobileFrameService);
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	public courseService = inject(LearningCourseService);
	private flyoutService = inject(FlyoutService);

	private readonly _courseLevelStructureId = signal<number | undefined>(undefined);
	public readonly courseLevelStructureId = this._courseLevelStructureId.asReadonly();


	public levelDetails = computed<LevelDetails | undefined>(() => {
		const courseLevelStructureId = this.courseLevelStructureId();
		const course = this.courseService.course();

		if (course && courseLevelStructureId) {
			const { level, index, parents, nextLevelUrl } = this.findLevelAndIndex(course.levels, courseLevelStructureId);

			if (level) {
				return {
					courseLevelStructureId,
					name: `${level.levelType} ${index}: ${level.levelTitle}`,
					description: level.levelDescription,
					infoContent: parents ?? '',
					activities: level.activities.map((activity, idx) => {
						const url = `learning/courses/${course.courseVersionId}/activity/${activity.courseActivityStructureId}`;
						return {
							icon: activity.response && activity.response.completedUTC ? 'fa-circle color-green' : 'fa-circle color-lightgray',
							iconStyle: 'fa-solid',
							text: `${activity.courseActivityType}: ${activity.activityTitle}`,
							subText: `${activity.response ?
								activity.response.completedUTC ? 'Completed' : 'Incomplete'
								: 'Not started'} ${activity.activityOptional ? "(Optional)" : ""}`,
							callback: () => {
								const prevActivity = level.activities[idx - 1] ?? undefined;
								if (idx != 0 && prevActivity && !prevActivity.activityOptional && !prevActivity.response?.completedUTC) {
									this.flyoutService.showMarkdownMessage(`This activity will be enabled once you have completed the previous activity.`, 'Message');
								}
								else this.router.navigate([this.courseService.urlService.withCurrentLanguage(url)]);
							}
						}
					}),
					childrenType: level.children.length ? level.children[0]!.levelType : undefined,
					children: this.buildChildren(level),
					nextLevelUrl,
				};
			}
		}

		return undefined;


	}, { equal: this.checkEquality })



	/**
 * Compare two auth state objects and determine if they are equivalent
 */
	private checkEquality(s1: LevelDetails | undefined, s2: LevelDetails | undefined) {

		if (s1?.courseLevelStructureId !== s2?.courseLevelStructureId) return false;

		return true;
	}


	async ngOnInit() {
		const courseVersionId = +this.route.snapshot.params['courseVersionId'];
		const courseLevelStructureId = +this.route.snapshot.params['courseLevelStructureId'];
		this._courseLevelStructureId.set(courseLevelStructureId);
		this.frame.setUrlMetadata({
			url: `/learning/courses/${courseVersionId}/level/${courseLevelStructureId}`,
			backUrl: `/learning/courses/${courseVersionId}`,
			pageName: computed(() => `Course`),
			headerText: computed(() => `Course`),
		});
		await this.courseService.loadDataAndSetCourseVersion(courseVersionId);
	}



	private findLevelAndIndex(levels: CourseLevel[], courseLevelStructureId: number): { level: CourseLevel | undefined; index: number; parents: string; nextLevelUrl: string } {
		let index = 0; // Initialize the level index for the outer levels

		for (const level of levels) {
			index++; // Increment the level index
			const parents = [`${level.levelType} ${index}`]; // Start with the current level's string

			// Check if the current level matches the courseLevelStructureId
			if (level.courseLevelStructureId === courseLevelStructureId) {
				// Find the siblingLevelId if available
				const siblingLevelId = levels[index] ? levels[index]!.courseLevelStructureId : undefined;
				const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;
				return { level, index, parents: parents.join(' > '), nextLevelUrl };
			}

			// Check direct children for a match
			let childIndex = 0; // Initialize index for children
			for (const child of level.children) {
				if (child.courseLevelStructureId === courseLevelStructureId) {
					parents.push(`${child.levelType} ${childIndex + 1}`); // Add the current child level type and index
					// Find the siblingLevelId for the child level if available
					const siblingLevelId = level.children[childIndex + 1] ? level.children[childIndex + 1]!.courseLevelStructureId : undefined;
					const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;
					return { level: child, index: childIndex + 1, parents: parents.join(' > '), nextLevelUrl };
				}

				// Check grand-children for a match
				let grandChildIndex = 0; // Initialize index for grandchildren
				for (const grandChild of child.children) {
					if (grandChild.courseLevelStructureId === courseLevelStructureId) {
						parents.push(`${child.levelType} ${childIndex + 1}`); // Add the current child level type and index
						parents.push(`${grandChild.levelType} ${grandChildIndex + 1}`); // Add the grandchild level type and index
						// Find the siblingLevelId for the grandchild level if available
						const siblingLevelId = child.children[grandChildIndex + 1] ? child.children[grandChildIndex + 1]!.courseLevelStructureId : undefined;
						const nextLevelUrl = siblingLevelId ? `../${siblingLevelId}` : `../..`;

						return { level: grandChild, index: grandChildIndex + 1, parents: parents.join(' > '), nextLevelUrl };
					}
					grandChildIndex++; // Increment grandchild index
				}
				childIndex++; // Increment child index
			}
		}

		return {
			level: undefined,
			index: -1,
			parents: '',
			nextLevelUrl: '../..',
		}; // Not found
	}



	private buildChildren(level: CourseLevel): Child[] | undefined {
		let children: Child[] | undefined = undefined
		if (!level.children.length) return children;

		children = level.children.map((child, idx) => {

			let subText = 'No Activities';
			let progress: {
				text: string,
				percentage: number,
			} | undefined = undefined;

			if (child.children.length) {
				subText = `Contains ${child.children.length} ${child.children[0]!.levelType}`;
			}
			else if (child.activities.length) {
				const completedActivities = child.activities.filter(activity => activity.response && activity.response.completedUTC).length;
				subText = `${completedActivities} of ${child.activities.length} Activities Completed`;
			}


			if (!child.children.length && child.activities.length) {
				const activitiesCompleted = child.activities.filter(activity => activity.response && activity.response.completedUTC).length;
				progress = {
					percentage: Math.round((activitiesCompleted / child.activities.length) * 100),
					text: `${activitiesCompleted} of ${child.activities.length} Activities Complete`
				};
			}


			return {
				name: `${child.levelType} ${idx + 1}: ${child.levelTitle}`,
				type: child.levelType,
				subText,
				progress,
				description: child.levelDescription,
				courseLevelStructureId: child.courseLevelStructureId,
				additionalDescription: child.children.length ? {
					type: child.children[0]!.levelType,
					levels: child.children.map(child2 => {
						return child2.levelTitle;
					})
				} : undefined,

			}
		});


		return children;
	}



	onNextClick() {

	}

}