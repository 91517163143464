<mobile-page-part>
	<div class="info-content">
		<h3>Course Overview</h3>
	</div>


	@if(course(); as course){
	<div class="white-box">
		<h4>{{course.title}}</h4>
		<div class="d-flex flex-column gap-2">
			<div class="d-flex justify-content-between">
				<span>Started: {{course.enrollmentDate}} </span>
				<span>{{course.completedPercentage}}% Complete</span>
			</div>
			<common-progress-bar [percentage]="course.completedPercentage"></common-progress-bar>
		</div>
	</div>

	<mobile-list [items]="course.activityList"></mobile-list>


	@for(level1 of course.levels; track level1.courseLevelStructureId; let idx1 = $index;){
	<div class="d-flex flex-column gap-2">
		<a class='link ps-1' routerLink="level/{{level1.courseLevelStructureId}}">
			<h3 class="mb-0">{{level1.levelType}} {{idx1 + 1}}: {{level1.levelTitle}}</h3>
		</a>

		@if(level1.activitiesList.length){
		<mobile-list [items]="level1.activitiesList"></mobile-list>
		}
		@for(level2 of level1.children; track level2.courseLevelStructureId; let idx2 = $index;){

		<a class='link ps-2' routerLink="level/{{level2.courseLevelStructureId}}">
			<h4 class="mb-0">{{level2.levelType}} {{idx2 + 1}}: {{level2.levelTitle}}</h4>
		</a>

		@if(level2.activitiesList.length){
		<mobile-list [items]="level2.activitiesList"></mobile-list>
		}

		@if(level2.children.length){
		<div ngbAccordion [closeOthers]="true">
			@for(level3 of level2.children; track level3.courseLevelStructureId; let idx3 = $index;){
			<div ngbAccordionItem [collapsed]="level3.activitiesStartedCount > 0 ? false : true">
				<div ngbAccordionHeader>
					<button ngbAccordionButton class="btn btn-flat p-2">
						<div class="d-flex flex-column gap-0">
							<div>{{level3.levelType}} {{idx3 + 1}}: {{level3.levelTitle}}</div>
							<small>
								<i>{{level3.activitiedCompletedMessage}}</i>
							</small>
						</div>
					</button>
				</div>

				<div ngbAccordionCollapse>
					<div ngbAccordionBody class="p-2">
						<ng-template>
							<div class="d-flex flex-column gap-1">
								@if(level3.hasActivities){
								<common-progress-bar [percentage]="level3.percentComplete"></common-progress-bar>

								<div>{{level3.activitiedCompletedMessage}}:</div>
								<ul>
									@for(level3Activity of level3.activities; track
									level3Activity.courseActivityStructureId){
									<li>
										{{level3Activity.courseActivityType}}:
										{{level3Activity.activityTitle}}
										@if(level3Activity.activityOptional){
										<i> (Optional)</i>
										}
									</li>
									}
								</ul>

								}
								@else {
								<div class="text-center color-gray">No Activities Available</div>
								}

								<common-button-row [center]="true">
									@if(level3.pendingActivityId){
									<button class="btn btn-large btn-primary"
										routerLink="activity/{{level3.pendingActivityId}}">Resume</button>
									}
									@else {
									<button class="btn btn-large btn-primary"
										routerLink="level/{{level3.courseLevelStructureId}}">View</button>
									}
								</common-button-row>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
			}

		</div>
		}


		}

	</div>
	}
	}
	@else {
	<common-message alertType="warning">You are not enrolled in this course. TBD</common-message>
	}
</mobile-page-part>